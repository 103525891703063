import React, { useEffect, useRef, useState } from 'react';
import { Alert, Box, Divider, Grid, Skeleton, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../common/components/PageContainer';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as _ from "lodash";
import { VendorState } from '../../../redux/vendor/rootReducer';
import { VendorsService } from '../../../modules/superadmin/our-vendors/services/vendors.service';
import { Vendor } from '../../../modules/superadmin/our-vendors/models/vendor.model';
import { VendorPreview } from '../../../modules/superadmin/edit-vendor/components/vendor-preview/vendor-preview.component';
import { VendorGeneralInformation } from '../../../modules/superadmin/edit-vendor/components/vendor-general-information.form';
import { Edit } from '@mui/icons-material';
import { getBase64, getDiff } from '../../../common/utils/utils';
import { VendorSpaces } from '../../../modules/superadmin/edit-vendor/components/vendor-spaces.form';
import { VendorConfig } from '../../../modules/superadmin/edit-vendor/components/vendor-config.form';
import { setLoading } from '../../../redux/vendor/ui/uiActions';

export const EditVendor = () => {
    const [t,] = useTranslation();
    const [vendorsService] = useState<VendorsService>(new VendorsService());
    const [vendor, setVendor] = useState<Vendor>({})
    const [intialVendor, setInitialVendor] = useState<Vendor>({})
    const [submitted, setSubmitted] = useState<boolean>(false)
    let { vendorId } = useParams();
    const [refreshKey, setRefreshKey] = useState(0);
    const logoInputRef = useRef(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setLoading(true))
        if(vendorId){
            setTimeout(() => {
                setVendorForEdit()
                dispatch(setLoading(false))
            }, 1000);
        } else {
            setVendor({
                maxSpaces: 3,
                config: { 
                    fontFamily: "Lexend", 
                    language: ["fr"], 
                    secondaryColor: "#217ee8", 
                    primaryColor: "#217ee8"
                }})
                dispatch(setLoading(false))
        }
        handleRefresh()
    }, [vendorId])

    const handleRefresh = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };

    const setVendorForEdit = async () => {
        const vendor = await vendorsService.getVendor(vendorId)
        setVendor(vendor)
        setInitialVendor(vendor)
    }

    const pickImageHandler = (event) => {
        logoInputRef.current.click();
    }

    const handleCompanyLogoChange = async(event) => {
        const file = event.target.files[0];
        const path = await getBase64(file);
        setVendor({ 
            ...vendor, 
            config: {
                ...vendor.config,
                logo: path
            }
        })
    }

    const handleSave = async () => {
        setSubmitted(true)
        if(!vendor.maxSpaces){
            vendor.maxSpaces = 3
        }
        if(vendor.name && vendor.alias && vendor.email && vendor.storeUrl && vendor.areas && vendor.areas.length > 0 && vendor.config.logo && vendor.spaces && vendor.spaces.length > 0){
            dispatch(setLoading(true))
            try {
                if(!vendorId)
                    await vendorsService.createVendor(vendor)
                else {
                    await vendorsService.patchVendor(vendor._id, getDiff(intialVendor, vendor))
                }
                
                dispatch(setLoading(false))
                setSubmitted(false)
                navigate("/vendors", {replace: true})
            } catch (error) {
                console.log(error)
            }
           
        }
    }

    return (
        <PageContainer title={t(vendorId ? 'Edit':'Add') +  ` vendor: ${vendor.name??""}`}>
            <Grid container spacing={2} flex={2} padding={2} key={refreshKey}>
                <Grid item md={8} xs={12}>
                    <VendorGeneralInformation vendor={vendor} setVendor={setVendor} submitted={submitted}/>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box position={"relative"}>
                        {vendor.config?.logo && <img src={vendor.config?.logo} alt={vendor.config?.logo} width="100%" style={{borderRadius: "20px"}}/>}
                        {!vendor.config?.logo && <Skeleton variant="rectangular" height={200} width={"100%"} ></Skeleton>}
                        <Edit sx={{backgroundColor: "primary.main", position: "absolute", right: 10, top: 10, padding: 1, borderRadius: 20, cursor: "pointer"}} onClick={pickImageHandler}/>
                    </Box>
                    <input
                        type="file"
                        ref={logoInputRef}
                        name="image"
                        accept="image/*"
                        onChange={handleCompanyLogoChange}
                        style={{ display: 'none' }}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <VendorConfig vendor={vendor} setVendor={setVendor} submitted={submitted} />
                    <VendorSpaces vendor={vendor} setVendor={setVendor} />
                </Grid>
            </Grid>
            {(!vendor.spaces || (vendor.spaces && vendor.spaces.length === 0)) && submitted && <Alert color='error' sx={{marginBlock: 0.5}}>You must select at least one space</Alert>}
            {(!vendor.areas || (vendor.areas && vendor.areas.length === 0)) && submitted && <Alert color='error' sx={{marginBlock: 0.5}}>You must select at least one area</Alert>}
            {(vendor.config && !vendor.config.logo) && submitted && <Alert color='error' sx={{marginBlock: 0.5}}>You must select a logo for the vendor</Alert>}
            <Box display="flex" justifyContent="flex-end" mb={2} marginBlock={1}>
                <Box display={"flex"} alignItems={"center"} 
                    onClick={() => handleSave()}
                    sx={{cursor: "pointer", bgcolor: "primary.text", paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content"}}>
                    <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>{`${vendorId ? "Save changes":"Create vendor"}`}</Typography>
                </Box>
            </Box>
        </PageContainer>
    );
};

