import { Box, Typography } from "@mui/material"

import LoadingImage from "../../assets/loader.gif"
import { useSelector } from "react-redux"
import { VendorState } from "../../redux/vendor/rootReducer"
import { useEffect, useState } from "react"

export const Loader = () => {
    const { isLoading } = useSelector((state: VendorState) => state.ui);
    const [isLoaderVisible, setIsLoaderVisible] = useState<Boolean>()
    const [loaderText, setLoaderText] = useState<string>()

    useEffect(() => {
        if(!isLoading){
            setTimeout(() => {
                setIsLoaderVisible(false)
            }, 2000);
            setTimeout(() => {
                setLoaderText("Done")
            }, 1000);
        } else {
            setLoaderText("Loading...")
            setIsLoaderVisible(true)
        }
        setTimeout(() => {
            setIsLoaderVisible(false)
        }, 15000);
    }, [isLoading])


    return <>{isLoaderVisible && <Box position={"absolute"} zIndex={10000} 
                            display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}
                            sx={{userSelect: "none"}}
                            width={"100vw"} height={"100vh"} bgcolor={"#00000090"} top={0} left={0}>
                    <img src={LoadingImage} width={"75px"}/>
                    <Typography fontSize={18} letterSpacing={-0.5} 
                                color={"primary.light"} fontWeight={"bolder"} textTransform={'capitalize'}>{loaderText}</Typography>
                </Box>}
    </>
}