import { useDispatch } from "react-redux"
import axios from "../../interceptors/api-client";
import { User } from "../models/user.model";


export class UsersService {
    dispatch = useDispatch()

    async authenticateUser(email, password) {
      let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_PMS_API_URL}/user/signin`,
          data: {
            email,
            password
          }
        };
        try {
          const {data} = await axios.request(config)
          return data
        } catch (error) {
        }
    }

    async getUsers() {
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_PMS_API_URL}/users`,
        };
        try {
          const {data} = await axios.request(config)
          return data
        } catch (error) {
        }
    }

    async getUser(userId) {
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_PMS_API_URL}/users`,
        };
        try {
          const {data} = await axios.request(config)
          return data.find((user: User) => user._id === userId)
        } catch (error) {
        }
    }

    async deleteUser(user: User) {
      let config = {
          method: 'delete',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_PMS_API_URL}/users/${user._id}`,
        };
        try {
          const {data} = await axios.request(config)
          return data
        } catch (error) {
        }
    }

    async createUser(user) {
      let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_PMS_API_URL}/users`,
          data: user
        };
        try {
          const {data} = await axios.request(config)
          return data
        } catch (error) {
        }
    }


    async patchUser(user) {
      let config = {
          method: 'patch',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_PMS_API_URL}/users`,
          data: user
        };
        try {
          const {data} = await axios.request(config)
          return data
        } catch (error) {
      }
    }

}