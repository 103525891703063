import { Box, Grid, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react";

import UploadIcon from      "../../../../../assets/icons/upload-icon.png"
import BrickBondIcon from   "../../../../../assets/icons/products/patterns/brick-bond-icon.png"
import { ProductImages } from "./upload-methods/product-images.component";
import { ArrowBack } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { EditProduct } from "../../models/edit-product.model";
import * as _ from "lodash"

interface ProductImagesPicker {
    editProduct: EditProduct
    setEditProduct,
    color: string
}

export const ProductImagesPicker = (props: ProductImagesPicker) => {
    const {color, editProduct, setEditProduct} = props
    const theme = useTheme();
    const [selectedMethod, setSelectedMethod] = useState<"plain" | "seamless">(editProduct.colorsImageType?editProduct.colorsImageType[color]:null)
    let { productId } = useParams();

    useEffect(() => {
        if(productId){
            if(!editProduct.colorsImageType || (editProduct.colorsImageType && !editProduct.colorsImageType[color]))
                if(editProduct.variants.find(variant => variant.getColor() === color))
                    handleImageMethodSelection("seamless")
        }
    }, [])

    const handleImageMethodSelection = (selection) => {
        setSelectedMethod(selection)
        setEditProduct(_.cloneDeep(editProduct.setColorImageType(color, selection)))
    }

    return <Box>
                {selectedMethod && <Box display={"flex"} >
                    <ArrowBack sx={{marginRight: 2, cursor: "pointer"}} onClick={() => handleImageMethodSelection(null)}/>
                    {theme.palette.mode !== 'dark' && <Typography fontSize={16} letterSpacing={-0.5} fontWeight="bolder" textAlign="center" textTransform={"capitalize"} color={"primary.contrastText"}>{selectedMethod} upload</Typography>}
                </Box>}
                {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.text"}>Upload for "{color}" color</Typography>}
                {!selectedMethod && <Grid container columnSpacing={2} rowSpacing={1} >
                    <Grid item md={6} xs={12} position={"relative"} display={"flex"} alignItems={"center"}>
                        <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{cursor: 'pointer'}} flex={1} onClick={() => handleImageMethodSelection("plain")}
                            bgcolor={"primary.main"} 
                            display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                                <img src={UploadIcon} style={{height: "45px", padding: "10px", 
                                    filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
                                {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.contrastText"}>Upload plain images</Typography>}
                                
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12} position={"relative"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{cursor: 'pointer'}} flex={1} onClick={() => { handleImageMethodSelection("seamless") }}
                            bgcolor={"primary.main"} 
                            display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                                <img src={BrickBondIcon} style={{height: "45px", padding: "10px", 
                                    filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
                                {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.contrastText"}>Upload seamless image</Typography>}
                                
                        </Box>
                    </Grid>
                </Grid>}
                {selectedMethod && <>
                    {selectedMethod === "plain" && <ProductImages {...props} />}
                    {selectedMethod === "seamless" && <Box marginBlock={4}><Typography fontSize={18} letterSpacing={-0.5} textAlign={"center"} color={"primary.contrastText"}>Images to be defined in the next step</Typography></Box>}
                    {selectedMethod === "seamless" && productId && <Box marginBlock={4}>
                        <Typography fontSize={14} letterSpacing={-0.5} textAlign={"center"} color={"primary.contrastText"}>You can modify seamless image of the product in the next step, otherwise, if you wish to create a seamless texture based on images of your product, click the following button  </Typography>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={2} >
                            <Box bgcolor={"primary.text"} display={"flex"} padding={1} borderRadius={2} onClick={() => handleImageMethodSelection("plain")} sx={{cursor: "pointer"}}>
                                {theme.palette.mode !== 'dark' && <Typography fontSize={14} letterSpacing={-0.5}  textAlign="center" textTransform={"capitalize"} color={"primary.main"}>Plain upload</Typography>}
                            </Box>
                        </Box>    
                    </Box>}
                </>}
            </Box>
}

