import { Box, Typography, useTheme } from "@mui/material";

import * as _ from "lodash"
import { EditProduct } from "../../models/edit-product.model";

interface ProductPatternProps {
    editProduct: EditProduct
    setEditProduct
    pattern: string
    icon
    disabled: boolean
}

export const ProductPattern = (props: ProductPatternProps) => {
    const {editProduct, setEditProduct, pattern, icon, disabled = false} = props
    const theme = useTheme();

    const handlePatternToggle = () => {
        setEditProduct(_.cloneDeep(editProduct.togglePattern(pattern)))
    }

    const isSelected = (pattern) => {
        return editProduct.patterns.findIndex(p => p.toLowerCase() === pattern.toLowerCase()) >= 0
    }

    return (<Box borderRadius={2} paddingBlock={1} border={"1px solid gray"} onClick={handlePatternToggle} sx={{cursor: disabled ? "not-allowed":"pointer"}}
        bgcolor={isSelected(pattern)?"secondary.main":"primary.main"} 
        display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
        <img src={icon} style={{height: "45px", padding: "10px", 
            filter: (theme.palette.mode === 'dark' || isSelected(pattern)) ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
        {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} color={isSelected(pattern)?"primary.main":"primary.contrastText"}>{pattern}</Typography>}
        {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} color={"primary.text"}>{pattern}</Typography>}
    </Box>)
}
