import { Check, Close, Edit, Language } from "@mui/icons-material";
import { Box, Button, Divider, Grid, MenuItem, Select, Skeleton, TextField, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SettingsService } from "../../../vendor/settings/services/settings.service";
import { SketchPicker } from 'react-color'
import { useSelector } from "react-redux";
import { VendorState } from "../../../../redux/vendor/rootReducer";
import { getBase64 } from "../../../../common/utils/utils";

export const VendorConfig = ({vendor, setVendor, submitted}) => {
    const [t,] = useTranslation();
    const [fonts, setFonts] = useState<any>([])
    const [settingsService] = useState<SettingsService>(new SettingsService())
    const {theme } = useSelector((state: VendorState) => state.settings);

    const [isOnEditPrimaryColor, setIsOnEditPrimaryColor] = useState(false);
    const [isOnEditSecondaryColor, setIsOnEditSecondaryColor] = useState(false);

    const [tempPrimaryColor, setTempPrimaryColor] = useState(theme?.primaryColor)
    const [tempSecondaryColor, setTempSecondaryColor] = useState(theme?.secondaryColor)

    useEffect(() => {
        const getFonts = async () => {
            const fonts = await settingsService.getFonts()
            setFonts(fonts)
        }
        getFonts()
    }, [])

    const [fields, setFields] = useState({
        language: {
            isTouched: false,
            label: "Language*",
            name: "language"
        },
        fontFamily: {
            isTouched: false,
            label: "Font Family*",
            name: "fontFamily"
        },
        primaryColor: {
            isTouched: false,
            label: "Primary Color*",
            name: "primaryColor"
        },
        secondaryColor: {
            isTouched: false,
            label: "Secondary Color*",
            name: "secondaryColor"
        },
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            isTouched: true
        }})
        setVendor({ 
            ...vendor, 
            config: {
                ...vendor.config,
                [name]: name === fields.language.name ? [value] : value
            }
        })
    }

    const handlePrimaryColorChange = (color) => {
        setTempPrimaryColor(color.hex)
    };
    const handleSecondaryColorChange = (color) => {
        setTempSecondaryColor(color.hex)
    };

    const validatePrimaryColor = () => {
        setVendor({ 
            ...vendor, 
            config: {
                ...vendor.config,
                primaryColor: tempPrimaryColor
            }
        })
        setIsOnEditPrimaryColor(false)
    }

    const validateSecondaryColor = () => {
        setVendor({ 
            ...vendor, 
            config: {
                ...vendor.config,
                secondaryColor: tempSecondaryColor
            }
        })
        setIsOnEditSecondaryColor(false)
    }


    const isValidInput = (name) => {
        return (submitted && !vendor[name]) || (fields && fields[name].isTouched && !vendor[name])
    }

    return <Box bgcolor={"primary.light"}  padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"} marginTop={1}>
    <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>Config</Typography>
    <Divider />
    <Grid container columnSpacing={3} rowSpacing={1} marginTop={1}>
        <Grid item md={4}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.language.label}</Typography>
            <Select
                    value={vendor.config?.language?vendor.config?.language[0]:"fr"}
                    name={fields.language.name}
                    onChange={handleChange}
                    inputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                    size='small'
                    fullWidth
                >
                    <MenuItem value="fr" sx={{  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" }}>🇫🇷 Français</MenuItem>
                    <MenuItem value="en" sx={{  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" }}>🇬🇧 English</MenuItem>
                    <MenuItem value="es" sx={{  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" }}>🇪🇸 Española</MenuItem>
                    <MenuItem value="ar" sx={{  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" }}>🇸🇦 العربية</MenuItem>
                </Select>
        </Grid>
        <Grid item md={4}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.fontFamily.label}</Typography>
            <Select
                value={(vendor?.config?.fontFamily??fonts[0]) || ""}
                name={fields.fontFamily.name}
                onChange={handleChange}
                inputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                size='small'
                fullWidth
                color="secondary"
                >
                {fonts?.map((font, index) => 
                    <MenuItem value={font} key={index} sx={{ backgroundColor: "primary.main" }}><Typography fontFamily={font} sx={{color: "primary.contrastText", fontSize: "10pt"}}>{font}</Typography></MenuItem>
                )}
            </Select>
        </Grid>
        <Grid item md={2}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.primaryColor.label}</Typography>
            {!isOnEditPrimaryColor && <Box bgcolor={vendor.config?.primaryColor??"#217ee8"} height={40} borderRadius={2} display={"flex"} justifyContent={"flex-end"} >
                <Edit sx={{backgroundColor: "primary.main", padding: 0.5, margin:0.5, borderRadius: 20, cursor: "pointer", color: "primary.text"}} onClick={() => setIsOnEditPrimaryColor(true)}/>
            </Box>}
            {isOnEditPrimaryColor && <Box sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                <SketchPicker sx={{ width: '100%' }} color={tempPrimaryColor}
                    onChange={handlePrimaryColorChange}/>
                <Box>
                    <Button variant="outlined"  sx={{margin: 1, color: "primary.text", borderColor: "primary.text"}} onClick={() => validatePrimaryColor()} ><Check /></Button>
                    <Button variant="outlined"  sx={{margin: 1, color: "primary.text", borderColor: "primary.text"}} onClick={() => {
                        setTempPrimaryColor(vendor.config?.primaryColor)
                        setIsOnEditPrimaryColor(false)
                        }} ><Close />
                    </Button>
                </Box>
                </Box>}
        </Grid>
        <Grid item md={2}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.secondaryColor.label}</Typography>
            {!isOnEditSecondaryColor && <Box bgcolor={vendor.config?.secondaryColor??"#217ee8"} height={40} borderRadius={2} display={"flex"} justifyContent={"flex-end"}>
            <Edit sx={{backgroundColor: "primary.main", padding: 0.5, margin:0.5, borderRadius: 20, cursor: "pointer", color: "primary.text"}} onClick={() => setIsOnEditSecondaryColor(true)}/>
            </Box>}
            {isOnEditSecondaryColor && <Box sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                <SketchPicker sx={{ width: '100%' }} color={tempSecondaryColor}
                    onChange={handleSecondaryColorChange}/>
                <Box>
                    <Button variant="outlined"  sx={{margin: 1, color: "primary.text", borderColor: "primary.text"}} onClick={() => validateSecondaryColor()} ><Check /></Button>
                    <Button variant="outlined"  sx={{margin: 1, color: "primary.text", borderColor: "primary.text"}} onClick={() => {
                        setTempSecondaryColor(vendor.config?.primaryColor)
                        setIsOnEditSecondaryColor(false)
                        }} ><Close />
                    </Button>
                </Box>
                </Box>}
        </Grid>
    </Grid>
</Box>
}