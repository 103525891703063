import {FC, useContext, useEffect, useState} from 'react'
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoginPage from '../pages/common/auth/login.page'
import { authContext } from '../providers/AuthProvider';
import { VendorPrivateRoutes } from './vendor.private-routes.router';
import { SuperAdminPrivateRoutes } from './super-admin.private-routes.router';

const SubRouting: FC = (props: any) => {
    const { hasAccess, isSuperAdmin } = useContext(authContext);
    const location = useLocation()
    const [initialLocation, setInitialLocation] = useState("/")

    useEffect(() => {
        setInitialLocation(location.pathname !== "/login"?location.pathname:"/dashboard")
    }, [])

    if(hasAccess){
        if(isSuperAdmin)
            return <Routes>
                <Route path="/login" element={<Navigate to={initialLocation} />} />
                <Route path="/*" element={<SuperAdminPrivateRoutes />} />
            </Routes>
        return <Routes>
            <Route path="/login" element={<Navigate to={initialLocation} />} />
            <Route path="/*" element={<VendorPrivateRoutes />} />
        </Routes>
    } else {
        return <Routes>
            <Route path="/login" element={<LoginPage originalLocation={location.pathname}/>} />
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    }
}

const AuthRouter: FC = (props: any) => {

   return (
        <SubRouting {...props} />
   )
}

export { AuthRouter }