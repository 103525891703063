import { Box, createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import { authContext } from '../../providers/AuthProvider';
import { theme } from '../../theme';
import { useContext, useState } from 'react';
import { Loader } from './Loader';
import { SettingsService } from '../../modules/vendor/settings/services/settings.service';


const ThemeWrapper = ({ children }) => {
    const [mode, setMode] = useState<'light' | 'dark'>('light');

    const { isSuperAdmin, vendorSettings } = useContext(authContext);
    const isMobileAndDown = useMediaQuery(theme.breakpoints.down('sm'));

    const VendorBg = `radial-gradient( circle farthest-corner at 10% 20%, ${vendorSettings?.primaryColor??'rgba(37,145,251,0.98)'} 0.1%, ${vendorSettings?.secondaryColor??'rgba(0,7,128,1)'} 99.8% )`
    const superAdminBg = `radial-gradient( circle farthest-corner at 10% 20%, #6C52AF 0.1%, #6C52AF50 99.8% )`


    const vendorTheme = createTheme({
        palette: {
            mode: mode,
            primary: {
                main:           mode === "dark" ? "#000000":"#FFFFFF",
                light:          mode === "dark" ? "#111111":"#EFF2F6",
                dark:           mode === "dark" ? "#111111":"#EFF2F6",
                text:           mode === "dark" ? "#FFFFFF":"#000000",
                contrastText:   mode === "dark" ? "#8A8A8C":"#000000"
            },
            secondary: {
                main:           mode === "dark" ? "#003ca9":vendorSettings?.secondaryColor??"#003ca9",
                light:          mode === "dark" ? "#00a4ff":`${vendorSettings?.secondaryColor??"#003ca9"}60`
            }
        },
        typography: {
            fontFamily: "Lexend"
        }
    });

    const superAdminTheme = createTheme({
        palette: {
            mode: mode,
            primary: {
                main:           mode === "dark" ? "#000000":"#FFFFFF",
                light:          mode === "dark" ? "#111111":"#6C52AF10",
                dark:           mode === "dark" ? "#111111":"#6C52AF10",
                text:           mode === "dark" ? "#FFFFFF":"#000000",
                contrastText:   mode === "dark" ? "#8A8A8C":"#000000"
            },
            secondary: {
                main:           mode === "dark" ? "#6C52AF":"#6C52AF",
                light:          mode === "dark" ? "#6C52AF50":"#CCC0E4"
            }
        },
        typography: {
            fontFamily: "Lexend"
        }
    });


    return (
        <Box className="App" padding={isMobileAndDown ? "1vh": "2vh"} position={"relative"}
        style={{ 
            background: "#8BC6EC",
            backgroundImage: isSuperAdmin?superAdminBg:VendorBg,
        }} maxHeight={"100vh"}>
            <ThemeProvider theme={isSuperAdmin?superAdminTheme:vendorTheme}>
                {children}
                <Loader/>
            </ThemeProvider>
        </Box>
    );
};

export default ThemeWrapper;