import { Box, Typography, useTheme } from "@mui/material"
import { EditProduct } from "../../models/edit-product.model";
import { APPLICATION_TYPE } from "../../../my-products/models/product.model";
import { useEffect } from "react";
import * as _ from "lodash"

interface ApplicationTypeProps {
    editProduct: EditProduct
    setEditProduct
    application_type
    icon
}

export const ApplicationType = (props: ApplicationTypeProps) => {
    const theme = useTheme();

    const isSelected = () => {
        return props.editProduct.application_types.find(selectedType => selectedType.toLowerCase() === props.application_type.toLowerCase())
    }

    const handleSelectApplicationType = () => {
        props.setEditProduct(_.cloneDeep(props.editProduct.toggleApplicationType(props.application_type.toLowerCase())))
    }
    
    return <Box paddingInline={2} paddingBlock={1} marginInline={1} borderRadius={2} 
        border={"1px solid black"} 
        borderColor={isSelected()?"transparent":"gray"} 
        bgcolor={isSelected()?"secondary.main":"primary.main"} 
                flexDirection={"row"} alignItems="center" display={"flex"} height={"30px"}
                sx={{cursor: "pointer"}} 
                onClick={handleSelectApplicationType}>
            {isSelected() && <img src={props.icon} height={"20px"} style={{
                        filter:  "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)"}}/>}
            {!isSelected() && <img src={props.icon} height={"20px"} style={{
                        filter: theme.palette.mode === 'dark' ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>}

            <Typography fontSize={14} letterSpacing={0} 
                color={isSelected()?"white":"primary.text"}
                marginLeft={1} >{props.application_type}</Typography>
        </Box>
}