import {combineReducers} from "redux";
import productsReducer from "./products/productsReducer";
import uiReducer from "./ui/uiReducer";
import settingsReducer from "./settings/settingsReducer";
import settingsTempReducer from "./settings/settingsReducer";
import spacesReducer from "./spaces/spacesReducer";
import authReducer from "../auth/authReducer";

export interface VendorState {
    products,
    spaces,
    ui,
    settings,
    tempSettings
}

export interface AuthState {
    auth
}

const vendorState: VendorState = {
    products: productsReducer,
    spaces: spacesReducer,
    ui: uiReducer,
    settings: settingsReducer,
    tempSettings: settingsTempReducer,
}

const authState: AuthState = {
    auth: authReducer
}

const fullState = {
    ...authState,
    ...vendorState
}

const rootReducer = combineReducers(fullState);

export default rootReducer