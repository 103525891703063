import { useDispatch } from "react-redux";
import axios from "../../../../interceptors/api-client";
import FormData from 'form-data';
import { AxiosRequestConfig } from 'axios';
import { Product } from "../../my-products/models/product.model";

export class EditProductService {
    dispatch = useDispatch();


    async updateProductSiblings(productId, siblingsIds) {
        let data = new FormData();
        data.append('productData', JSON.stringify({
            siblings: siblingsIds
        }));
        
        try {
            // Define the Axios request configuration
            const config: AxiosRequestConfig<FormData> = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products/${productId}`,
                headers: { 'Content-Type': 'multipart/form-data' },
                data: data,
                timeout: 2 * 60 * 1000,
            };

            const response = await axios.request(config);
            return response
        } catch (error) {
            throw error; // Rethrow error to propagate it to the caller
        }
    }

    async createProduct(product: Product, file): Promise<Product> {
        let data = new FormData();
        data.append('productData', JSON.stringify(product.toPlainObject()));

        // Convert files to blobs and append to form data
        const fileRead = new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => {
                    const blob = new Blob([reader.result], { type: file.type });
                    data.append("image", blob, "file.jpg");
                    resolve(true);
                };
                reader.onerror = reject;
        });

        try {
            // Wait for all files to be read and appended to form data
            await Promise.resolve(fileRead)

            // Define the Axios request configuration
            const config: AxiosRequestConfig<FormData> = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products`,
                headers: { 'Content-Type': 'multipart/form-data' },
                data: data,
                timeout: 2 * 60 * 1000,
            };

            const response = await axios.request(config);
            return (new Product()).prepareProduct(response.data)
        } catch (error) {
            console.error('Error reading files or making the request:', error);
            throw error; // Rethrow error to propagate it to the caller
        }
    }

    async bulkCRUDProduct(defaultProductId: string, differences, files: any[]): Promise<Product> {
        let data = new FormData();
        data.append('data', JSON.stringify(differences))
        if(defaultProductId)
            data.append('defaultProductId', defaultProductId)

        // Convert files to blobs and append to form data
        const filesRead = files.map(file => 
            data.append("image", file)
        )

        try {
            // Wait for all files to be read and appended to form data
            await Promise.all(filesRead)

            // Define the Axios request configuration
            const config: AxiosRequestConfig<FormData> = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products/bulk`,
                headers: { 'Content-Type': 'multipart/form-data' },
                data: data,
                timeout: 2 * 60 * 1000,
            };

            const response = await axios.request(config);
            return (new Product()).prepareProduct(response.data)
        } catch (error) {
            console.error('Error reading files or making the request:', error);
            throw error; // Rethrow error to propagate it to the caller
        }
    }

    async deleteProduct(productId) {
        try {
            // Define the Axios request configuration
            const config: AxiosRequestConfig<FormData> = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products/${productId}`,
                timeout: 2 * 60 * 1000,
            };

            const response = await axios.request(config);
            return productId
        } catch (error) {
            console.error('Error deleting product or making the request:', error);
            throw error; // Rethrow error to propagate it to the caller
        }
    }

    async updateProduct(productId, product, file): Promise<Product> {
        let data = new FormData();
        data.append('productData', JSON.stringify(product.toPlainObject()));
        // Convert files to blobs and append to form data
        
        const fileRead = new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => {
                    const blob = new Blob([reader.result], { type: file.type });
                    data.append("image", blob, "file.jpg");
                    resolve(true);
                };
                reader.onerror = reject;
        });

        try {
            // Wait for all files to be read and appended to form data
            await Promise.resolve(fileRead)

            // Define the Axios request configuration
            const config: AxiosRequestConfig<FormData> = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products/${productId}`,
                headers: { 'Content-Type': 'multipart/form-data' },
                data: data,
                timeout: 2 * 60 * 1000,
            };

            const response = await axios.request(config);
            return (new Product()).prepareProduct(response.data)
        } catch (error) {
            console.error('Error reading files or making the request:', error);
            throw error; // Rethrow error to propagate it to the caller
        }
    }

    async generateSeamless(width, height, pattern, files) {
        let data = new FormData();
        data.append('plankWidth', width);
        data.append('plankHeight', height);
        data.append('textureWidth', '4');
        data.append('textureHeight', '4');
        data.append('pattern', pattern);

        // Convert files to blobs and append to form data
        const filePromises = files.map((file, index) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => {
                    const blob = new Blob([reader.result], { type: file.type });
                    data.append("files", blob, "file" + index + ".jpg");
                    resolve(true);
                };
                reader.onerror = reject;
            });
        });

        try {
            // Wait for all files to be read and appended to form data
            await Promise.all(filePromises);
            // Define the Axios request configuration
            const config: AxiosRequestConfig<FormData> = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products/seamless`,
                headers: { 'Content-Type': 'multipart/form-data' },
                data: data,
                responseType: 'arraybuffer', // Ensure the response is handled as a binary buffer
                timeout: 2 * 60 * 1000,
            };

            const response = await axios.request(config);

            // Convert response data to base64 in chunks
            const uint8Array = new Uint8Array(response.data);
            let base64String = '';
            const chunkSize = 0x8000; // 32 KB chunks
            for (let i = 0; i < uint8Array.length; i += chunkSize) {
                base64String += String.fromCharCode.apply(null, Array.from(uint8Array.subarray(i, i + chunkSize)));
            }
            return btoa(base64String);
        } catch (error: any) {
            console.error('Error reading files or making the request:', error.response.data);
            throw error; // Rethrow error to propagate it to the caller
        }
    }
}
