import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2"
import { AnalyticsService } from "../../settings/services/analytics.service";
import { fillMissingDates } from "../../../../common/utils/utils";
import FadeIn from "react-fade-in";


export const VisitsAnalytics = ({startDate, endDate}) => {
    const [labels, setLabels] = useState()
    const [data, setData] = useState()
    const [analyticsService] = useState<AnalyticsService>(new AnalyticsService());

    const getAnalytics = async () => {
        let data = await analyticsService.queryAnalytics({
            statType: "VISITS_COUNT",
            startDate: startDate,
            endDate: endDate
        })
        data = fillMissingDates(data)
        setLabels(data.map(e => e._id))
        setData(data.map(e => e.count))
    }

    useEffect(() => {
        getAnalytics()
    }, [startDate, endDate])

    return <FadeIn><Line 
        style={{"width": "100%"}}
        data={{
            labels: labels,
            datasets: [{
                label: 'Visits',
                data: data,
                fill: false,
                backgroundColor: "#0C2DA2",
                borderColor: "#0C2DA2",
                tension: 0.1,
            }],
        }} 
        options={{
            responsive: true,
            plugins: {
            tooltip: {
                mode: 'index',
                intersect: false
            },
            title: {
                display: true,
                text: 'Number of visits per day'
            }
            },
            hover: {
                mode: 'index',
            },
            scales: {
            x: {
                title: {
                    display: true,
                    text: 'Day'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Number of visits'
                },
                min: 0,
                ticks: {
                    stepSize: 1
                }
            }
            }
        }}/></FadeIn>
}