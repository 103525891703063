import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Space } from "../../../vendor/my-spaces/models/space.model";
import { SpacesService } from "../../../vendor/my-spaces/services/spaces.service";

export const SpaceView = ({selectedSpaceForViewID, setSelectedSpaceForView}) => {
    const [space, setSpace] = useState<Space>()
    const [spaceServices] = useState<SpacesService>(new SpacesService())
    
    useEffect(() => {
        const getSpace = async () => {
            const result = await spaceServices.getSpace(selectedSpaceForViewID)
            setSpace(result)
        }
        getSpace()
    }, [selectedSpaceForViewID])

    return (
        <Fragment>
            <Dialog
                onClose={() => setSelectedSpaceForView(null)}
                open={space !== null}
                fullScreen={true}

            >
                {space && <DialogTitle sx={{ m: 0, p: 2 }} >
                    <Typography color={"primary.text"} fontSize={20} fontWeight={"bolder"} letterSpacing={0}>
                        {space.name}
                    </Typography>
                </DialogTitle>}
                <IconButton
                aria-label="close"
                onClick={() => setSelectedSpaceForView(null)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <Close />
                </IconButton>
                {space && <DialogContent dividers >
                    <Grid container spacing={10}>
                        <Grid item md={6} xs={12} sm={12}>
                            <img src={space.thumbnailPath} width={"100%"} style={{borderRadius: 6}}></img>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12}>
                           <table>
                                <tbody>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Height: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{space.height}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Width: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{space.width}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Surfaces: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{space.surfaces.join(",")}</Typography></td>
                                    </tr>
                                </tbody>
                           </table>

                        </Grid>
                    </Grid>
                    

                    
                   
                </DialogContent>}
            </Dialog>
        </Fragment>
    );
}