import { Box, Dialog, Divider, Grid, MenuItem, Modal, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpacesAsGrid } from "../../../vendor/my-spaces/components/spaces-as-grid.component";
import { SpacesService } from "../../../vendor/my-spaces/services/spaces.service";
import { Close, Edit, EditRounded } from "@mui/icons-material";
import { Space } from "../../../vendor/my-spaces/models/space.model";


export const VendorSpacesEditModal = ({vendor, setVendor, isModalOpen, setIsModalOpen}) => {
    const [spacesService] = useState<SpacesService>(new SpacesService());
    const [spaces, setSpaces] = useState(null)
    const [filteredSpaces, setFilteredSpaces] = useState(spaces);
    const [selectedSpaces, setSelectedSpaces] = useState([]);

    const [fields, setFields] = useState({
        maxSpaces: {
            isTouched: false,
            label: "Max spaces",
            name: "maxSpaces"
        }
    })

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        const allSpaces = await spacesService.getSpaces();
        setSpaces(allSpaces.data.filter((space: Space) => !space.uploadedByVisitor))
        setFilteredSpaces(allSpaces.data.filter((space: Space) => !space.uploadedByVisitor))
        setSelectedSpaces(vendor.spaces??[])
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            isTouched: true
        }})
        setVendor({ ...vendor, [name]: value })
    }

    const isValidInput = (name) => {
        return fields && fields[name].isTouched && !vendor[name]
    }

    const handleSave = () => {
        setVendor({
            ...vendor,
            spaces: selectedSpaces
        })
        setIsModalOpen(false)
    }

    return <Dialog open={true} fullWidth maxWidth="lg" >
    
        <Box bgcolor={"primary.light"} padding={2} position={"relative"} display={"flex"} flexDirection={"column"} >
            <Box display={"flex"} justifyContent={"space-between"}>
                <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>Spaces</Typography>
                <Close sx={{backgroundColor: "primary.main", borderRadius: "50%", padding: 1, marginTop: -1, hover: "pointer"}} onClick={() => setIsModalOpen(false)}/>
            </Box>

            <Divider />
            <Grid container columnSpacing={3} rowSpacing={1} marginTop={1} >
                <Grid item md={12} xs={12}>
                    <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.maxSpaces.label}</Typography>
                    <TextField
                            error={isValidInput(fields.maxSpaces.name)}
                            name={fields.maxSpaces.name}
                            value={vendor?.maxSpaces??3}
                            onChange={handleChange}
                            type="number"
                            fullWidth
                            required
                            size='small'
                            InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                        />
                </Grid>
                <Grid item md={12} xs={12}  height={"60vh"} marginTop={1} sx={{overflowY: "scroll"}}>
                    {spaces && <SpacesAsGrid    spaces={spaces}
                                                maxSpaces={vendor?.maxSpaces??3}
                                                selectedSpaces={selectedSpaces}
                                                setSelectedSpaces={setSelectedSpaces}
                                                filteredSpaces={spaces} />}
                </Grid>
                <Box display="flex" justifyContent="flex-end" paddingBlock={1} 
                        bottom={0}  width={"100%"}>
                    <Box display={"flex"} alignItems={"center"} 
                        onClick={() => handleSave()}
                        sx={{cursor: "pointer", bgcolor: "primary.text", paddingInline: 2, borderRadius: 2, width: "fit-content"}}>
                        <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>Validate</Typography>
                    </Box>
                </Box>
            </Grid>
        </Box>
</Dialog>

}