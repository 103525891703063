import { Alert, Box, Divider, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import FadeIn from "react-fade-in/lib/FadeIn";
import { ProductType } from "../../components/step-2/product-type.component";
import { ProductVariants } from "../../components/step-2/product-variants.component";
import { useDispatch, useSelector } from "react-redux";
import { VendorState } from "../../../../../redux/vendor/rootReducer";

import * as _ from "lodash"
import { EditProduct } from "../../models/edit-product.model";
import { PRODUCT_TYPES_PATTERNS } from "../../models/types.enums";

interface EditProductProps {
    editProduct: EditProduct
    setEditProduct,
    activeStep: number
}

export const ProductVariantsAndTypesForm = (props: EditProductProps) => {
    const [t] = useTranslation();
    const [types, setTypes] = useState([])
    const {editProductStepToValidate} = useSelector((state: VendorState) => state.products);

    useEffect(() => {
        let typesToApply = []
        props.editProduct.application_types.forEach(applicationType => {
            if(PRODUCT_TYPES_PATTERNS[applicationType])
                typesToApply = [...typesToApply, ...PRODUCT_TYPES_PATTERNS[applicationType]]

        })
        setTypes(_.uniqBy(typesToApply, "name"))
    }, [])

    const isValidType = () => {
        return editProductStepToValidate === props.activeStep && props.editProduct && !props.editProduct.type
    }

    const isValidSize = () => {
        return editProductStepToValidate === props.activeStep && props.editProduct && (!props.editProduct.sizes || (props.editProduct.sizes && props.editProduct.sizes.length === 0))
    }

    return  <FadeIn>
        <Box bgcolor={"primary.light"}  padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"}>
                    <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>{t("PAGES.NEW_PRODUCT.VARIANTS.TYPE")}</Typography>
                    <Divider />
                    <Grid container columnSpacing={3} rowSpacing={1} marginTop={1}>
                        {types?.map((type, index) => (
                                <Grid item key={index} md={4} xs={12} >
                                    <ProductType    type={type.name}
                                                    {...props}
                                                    icon={type.icon} />
                                </Grid>   
                        ))}                
                    </Grid>
                    {isValidType() && <Alert severity="error" sx={{marginTop: 2}}>Please select a type for your product</Alert>}
        </Box>
        <Box bgcolor={"primary.light"} marginTop={1} padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"}>
            <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>{t("PAGES.NEW_PRODUCT.VARIANTS.VARIANTS")}</Typography>
            <Divider />
            <Grid container columnSpacing={3} rowSpacing={1} marginTop={1}>
                <ProductVariants {...props}/>                 
            </Grid>
            {isValidSize() && <Alert severity="error" sx={{marginTop: 2}}>Please add at least a default size</Alert>}
        </Box>
    </FadeIn>

}






