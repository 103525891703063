import {
  Experimental_CssVarsProvider as CssVarsProvider,
  createTheme,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface DefaultTheme {
    spacing: (factor: number) => number;
  }
}

export const defaultPrimaryColor = '#0052cc'
export const defaultSecondaryColor = '#edf2ff'
export const defaultFonts = `"Lexend", "Arial", sans-serif`
export const defaultLogo = `https://ardealy.com/wp-content/uploads/2023/05/wpWhiteLogo.png`

export let theme = createTheme({
  spacing: (factor) => `${0.5 * factor}rem`,
  palette: {
    primary: {
      main: defaultPrimaryColor,
    },
    secondary: {
      main: defaultSecondaryColor,
    },
  },
  typography: {
    fontFamily: defaultFonts
    },
  
})

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
})