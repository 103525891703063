import React, { useEffect, useState } from 'react';
import { Typography, Container,  TextField, Button, Box, useMediaQuery, ToggleButtonGroup, ToggleButton, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ProductsService } from '../../../modules/vendor/my-products/services/products.service';
import { Product } from '../../../modules/vendor/my-products/models/product.model';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../common/components/ConfirmModal';
import { setLoading, toggleConfirmModal } from '../../../redux/vendor/ui/uiActions';
import { setProducts } from '../../../redux/vendor/products/productsActions';
import { ViewList, ViewModule } from '@mui/icons-material';
import { ProductsAsList } from '../../../modules/vendor/my-products/components/products-as-list.component';
import { ProductsAsGrid } from '../../../modules/vendor/my-products/components/products-as-grid.component';
import { TopNavBar } from '../../../common/Header/TopNavBar';
import { PageContainer } from '../../../common/components/PageContainer';
import { VendorState } from '../../../redux/vendor/rootReducer';

export const MyProducts = () => {
    const [t] = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { products } = useSelector((state: VendorState) => state.products);

    const [productsService] = useState<ProductsService>(new ProductsService());
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [filter, setFilter] = useState('');
    const [viewMode, setViewMode] = useState<'list' | 'grid'>('list'); // Track view mode
    const [modalProps, setModalProps] = useState({})

    const onDelete = (product) => {
        dispatch(toggleConfirmModal())
        setModalProps({
            title: t("PAGES.MY_PRODUCTS.CONFIRM.DELETE_TITLE"),
            description: t("PAGES.MY_PRODUCTS.CONFIRM.DELETE_DESCRIPTION"),
            handleAccepted: () => handleRemoveProduct(product)
        })
        
    }

    const handleRemoveProduct = async(product) => {
        dispatch(setLoading(true))
        await productsService.deleteProduct(product)
        await productsService.getProducts();
        dispatch(setLoading(false))
    }

    useEffect(() => {
        dispatch(setProducts([]))
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        dispatch(setLoading(true))
        await productsService.getProducts();
        dispatch(setLoading(false))
    }

    useEffect(() => {
        setFilteredProducts(products);
    }, [products]);

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilter(value);
        const filteredProducts = products.filter(product =>
            product.name.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            product.brand.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            product.application_types.some(type => type.toLowerCase().includes(value.toLowerCase())) ||
            product.siblings.some(sibling => sibling.name.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()))
        );
        setFilteredProducts(filteredProducts);
    };

    const handleEditProduct = (product: Product) => {
        dispatch(setLoading(true))
        navigate(`/products/${product.id}/edit`)
    }


    const toggleViewMode = (mode: 'list' | 'grid') => {
        setViewMode(mode === 'list' ? 'grid' : 'list');
    };

    return (
        <PageContainer title={t('PAGES.MY_PRODUCTS.TITLE')}>
                <Box display="flex">
                    <TextField
                            label={
                                <Typography variant='caption' sx={{fontSize: "10pt", letterSpacing: -0.5}}> {t('PAGES.MY_PRODUCTS.FILTER_TEXT')} </Typography>
                              }
                            variant="outlined"
                            color='secondary'
                            value={filter}
                            onChange={handleFilterChange}
                            sx={{marginBottom: 2, marginRight: 2, height: "40px", fontSize: "10pt", letterSpacing: -0.5}}
                            fullWidth
                            size='small'
                        />
                        
                    <ToggleButtonGroup
                            value={viewMode}
                            exclusive
                            aria-label="text alignment"
                            sx={{marginBottom: 2, height: "40px"}}
                        >
                            <ToggleButton value="list" aria-label="list" onClick={(mode) => toggleViewMode('grid')}>
                                <ViewList />
                            </ToggleButton>
                            <ToggleButton value="grid" aria-label="grid" onClick={(mode) => toggleViewMode('list')}>
                                <ViewModule />
                            </ToggleButton>


                    </ToggleButtonGroup>
                    
                </Box>
                
                {viewMode === 'list' ? (
                        <ProductsAsList products={products}
                                        filteredProducts={filteredProducts.filter(product => product.isDefault === true)}
                                        setFilteredProducts={setFilteredProducts}
                                        handleEditProduct={handleEditProduct}
                                        onDelete={onDelete}
                        />
                ) : (
                        <ProductsAsGrid products={products}
                                        filteredProducts={filteredProducts.filter(product => product.isDefault === true)}
                                        handleEditProduct={handleEditProduct}
                                        onDelete={onDelete}
                        />
                )}
                <ConfirmDialog {...modalProps} />
            
        </PageContainer>
    );
};

export default MyProducts;
