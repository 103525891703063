import { Check, EastRounded, WestRounded } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setEditProductStepToValidate } from "../../../../../redux/vendor/products/productsActions";
import { EditProductService } from "../../services/edit-product.service";
import { useNavigate, useParams } from "react-router-dom";
import { VendorState } from "../../../../../redux/vendor/rootReducer";
import { ProductVariant } from "../../models/variant.model";
import { Product } from "../../../my-products/models/product.model";
import { EditProductContext } from "../../../../../pages/vendor/products/edit-product.page";
import { EditProduct } from "../../models/edit-product.model";
import { setLoading } from "../../../../../redux/vendor/ui/uiActions";
import { base64ToBlob } from "../../../../../common/utils/utils";

interface StepperActionProps {
    editProduct: EditProduct
    activeStep, 
    setActiveStep, 
    nbSteps
}

export const StepperActions = (props: StepperActionProps) => {
    const {editProduct, activeStep, setActiveStep, nbSteps} = props
    const [t] = useTranslation();
    const dispatch = useDispatch()
    const [editProductService] = useState<EditProductService>(new EditProductService())
    const navigate = useNavigate()
    const {productId} = useParams()
    const isOnEditProduct = useContext(EditProductContext);

    const handleSubmitForm = async () => {
        dispatch(setEditProductStepToValidate(activeStep))
        const defaultVariant = editProduct.getDefaultVariant()
        if(editProduct.variants && editProduct.variants.length > 0 && defaultVariant) {
            dispatch(setLoading(true))
            const { added, removed, modified, unchanged } = editProduct.getVariantDifferences()
            await editProductService.bulkCRUDProduct(
                productId,
                {
                    modified: modified.map(variant => (new Product()).mapVariantIntoProduct(variant, editProduct).toPlainObject()),
                    created: added.map(variant => (new Product()).mapVariantIntoProduct(variant, editProduct).toPlainObject()),
                    removed: removed.map(variant => (new Product()).mapVariantIntoProduct(variant, editProduct).toPlainObject()).map((product: any) => product.uuid),
                },
                [...added, ...removed, ...modified].filter((variant: ProductVariant) => variant.getIsImageChanged()).map((variant: ProductVariant) => {
                    const blob = base64ToBlob(variant.getSeamless64URL())
                    const fileName = `image_${variant.productId}`
                    const file = new File([blob], `${fileName}.png`, { type: 'image/png' });
                    return file
                })
            )
            dispatch(setLoading(false))
            navigate("/products/catalog", {replace: true})
            // // Create new products
            // const createdProducts = await Promise.all(added.map(async(variant) => {
            //     return await editProductService.createProduct(
            //                     (new Product()).mapVariantIntoProduct(variant, editProduct), 
            //                     base64ToBlob(variant.getSeamless64URL()))
            // }))
            
            // // Remove unselected products
            // await Promise.all(removed.map(async(variant) => {
            //     return await editProductService.deleteProduct(variant.productUuid)
            // }))

            // // Update existing products
            // const updatedProducts = await Promise.all(modified.map(async(variant) => {
            //     return await editProductService.updateProduct(
            //                     variant.productUuid, 
            //                     (new Product()).mapVariantIntoProduct(variant, editProduct), 
            //                     base64ToBlob(variant.getSeamless64URL()))
            // }))

            // // Get all Ids to update siblings
            // const createdProductIds = createdProducts.map((product: Product) => ({
            //     id: product.id,
            //     uuid: product.uuid
            // }))
            // const updatedProductIds = updatedProducts.map((product: Product) => ({
            //     id: product.id,
            //     uuid: product.uuid
            // }))
            // const unchangedProductIds = unchanged.map((variant: ProductVariant) => ({
            //     id: variant.productId,
            //     uuid: variant.productUuid
            // }))

            // await Promise.all(createdProducts.map(async (product) => {
            //     //remove actual product from siblings list
            //     let productSiblingIds = [...createdProductIds, ...updatedProductIds, ...unchangedProductIds]
            //         .filter(np => product.id !== np.id)
            //     //update siblings
            //     return await editProductService.updateProductSiblings(product.uuid, productSiblingIds.map(e => e.id))
            // }))

            // await Promise.all(updatedProducts.map(async (product) => {
            //     //remove actual product from siblings list
            //     let productSiblingIds = [...createdProductIds, ...updatedProductIds, ...unchangedProductIds]
            //         .filter(np => product.id !== np.id)
            //     //update siblings
            //     return await editProductService.updateProductSiblings(product.uuid, productSiblingIds.map(e => e.id))
            // }))
            
        }
    }

    const handleGoToNextStep = () => {
        dispatch(setEditProductStepToValidate(activeStep))
        if(isValidStep()){
            setActiveStep(activeStep + 1)
        }
    } 

    const isValidStep = () => {
        if(activeStep === 0)
            return editProduct.name && editProduct.description 
                    && editProduct.brand && editProduct.application_types.length > 0
        else if(activeStep === 1)
            return editProduct.type && editProduct.sizes?.length > 0
        else if(activeStep === 2){
            let isValid = true
            editProduct.colors.map(color => {
                if(!editProduct.colorsImageType || !editProduct.colorsImageType[color]){
                    isValid = false
                } else {
                    if(editProduct.colorsImageType[color] === "plain"){
                        if(!editProduct.images || (editProduct.images[color] && editProduct.images[color].length === 0)){
                            isValid = false
                        }
                    }
                }
            })
            return editProduct.patterns && editProduct.patterns.length > 0 && isValid
        }
        else if(activeStep === 3)

            return (editProduct.variants && editProduct.variants.length > 0 
                // SKU must be present on every element
                && editProduct.variants.filter((variant:ProductVariant) => variant.getIsSelected()).every((variant: ProductVariant) => variant.getSku())
                // Seamless image must be present on every element
                && editProduct.variants.filter((variant:ProductVariant) => variant.getIsSelected()).every((variant: ProductVariant) => variant.getSeamless64URL())
                // Seamless height must be present on every element
                && editProduct.variants.filter((variant:ProductVariant) => variant.getIsSelected()).every((variant: ProductVariant) => variant.getSeamlessHeight())
                // Seamless width must be present on every element
                && editProduct.variants.filter((variant:ProductVariant) => variant.getIsSelected()).every((variant: ProductVariant) => variant.getSeamlessWidth())
                // At least one element is selected
                && editProduct.variants.findIndex((variant: ProductVariant) => variant.getIsSelected()) >= 0 
                //SKUs are unique
                && editProduct.variants.filter((variant: ProductVariant) => variant.getIsSelected()).
                        every((variant: ProductVariant, index: number, array: ProductVariant[]) =>  variant.getSku() && array.findIndex(v => v.getSku() === variant.getSku()) === index )
            )
        else if(activeStep === 4)
            return (editProduct.variants && editProduct.getSelectedOnlyVariants().length > 0 
               && editProduct.getSelectedOnlyVariants().find((variant: ProductVariant) => variant.getIsDefault())) 
    }

    const handleGoToPreviousStep = () => {
        dispatch(setEditProductStepToValidate(activeStep - 1))
        setActiveStep(activeStep - 1)
    }

    return <Box marginBlock={2} display={"flex"} justifyContent={activeStep> 0 ? "space-between": "flex-end"} width={"100%"}>
        {activeStep <= 4 && activeStep > 0 && 
        <Box display={"flex"} alignItems={"center"} onClick={handleGoToPreviousStep}
            sx={{cursor: "pointer", bgcolor: "primary.text", paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content"}}>
            <WestRounded sx={{fontSize: "13pt", color: "primary.main", padding: 0}}/>
            <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} marginLeft={1} color={"primary.main"} padding={0}>Back</Typography>
        </Box>}

        {activeStep < 4 && <Box display={"flex"} flexDirection={"row"}>
            {!isOnEditProduct && <Box display={"flex"} alignItems={"center"} onClick={handleGoToNextStep}
                sx={{marginRight: 1, cursor: "pointer", bgcolor: "primary.main", paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content", border: "1px solid black", borderColor: "primary.text"}}>
                <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.text"} padding={0}>Save draft</Typography>
            </Box>}
            <Box display={"flex"} alignItems={"center"} onClick={handleGoToNextStep}
                sx={{cursor: `${isValidStep()? "pointer":"not-allowed"}`, bgcolor: `${isValidStep()? "primary.text":"gray"}`, paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content"}}>
                <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} marginRight={1} color={"primary.main"} padding={0}>Next</Typography>
                <EastRounded sx={{fontSize: "13pt", color: "primary.main", padding: 0}}/>
            </Box>
        </Box>
        }

        {activeStep === 4 && <Box display={"flex"} alignItems={"center"} onClick={() => handleSubmitForm()}
            sx={{cursor: `${isValidStep()? "pointer":"not-allowed"}`, bgcolor: `${isValidStep()? "primary.text":"gray"}`, paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content"}}>
            <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} marginRight={1} color={"primary.main"} padding={0}>{t('PAGES.NEW_PRODUCT.SUBMIT')}</Typography>
            <Check sx={{fontSize: "13pt", color: "primary.main", padding: 0}}/>
        </Box>}
    </Box>
}
