import { useSelector } from "react-redux";
import { VendorState } from "../../../../../redux/vendor/rootReducer";
import { createRef, useEffect, useState } from "react";
import { Alert, Box, Divider, Grid, Typography } from "@mui/material";

import * as _ from "lodash"
import { EditProduct } from "../../models/edit-product.model";
import { ProductVariant } from "../../models/variant.model";
import { VariantRepresentation } from "./variant-representation.component";

interface ProductFinalizationProps {
    editProduct: EditProduct
    setEditProduct,
    activeStep: number
}

export const ProductsForSelection = (props: ProductFinalizationProps) => {
    const {editProduct, activeStep} = props
    const {editProductStepToValidate} = useSelector((state: VendorState) => state.products);
    const [elRefs, setElRefs] = useState([]);

    useEffect(() => {
        // add or remove refs
        setElRefs((elRefs) =>
          Array(editProduct.variants.length)
            .fill(0)
            .map((_, i) => elRefs[i] || createRef()),
        );
    }, [editProduct.variants.length]);

    return <>{
        editProduct.colors.length > 0 && editProduct.colors.map((color, index) => (
            <Box key={index} bgcolor={"primary.light"}  padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"} marginBottom={1}>
                {color !=="default" && <Typography fontSize={16} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} marginBlock={0.5}>{color}</Typography>}
                {color !=="default" && <Divider />}
                <Grid container spacing={2} marginTop={0.5}>
                {_.groupBy(editProduct.variants, "_color")[color]?.map((variant: ProductVariant, index) => {
                    return <VariantRepresentation key={index} variant={variant} elRefs={elRefs} {...props}/>
                })}
                </Grid>
            </Box>
        ))
    }
    {editProductStepToValidate === activeStep && !editProduct.variants.find(e => e.getIsSelected()) && 
        <Alert severity="error" sx={{marginTop: 2}}>Please select at least one product</Alert>}
    {editProductStepToValidate === activeStep && editProduct.variants.filter(e => e.getIsSelected()).find(e => !e.getSku()) && 
        <Alert severity="error" sx={{marginTop: 2}}>Please fill SKUs of each one of the selected products</Alert>}
    </>
}