import { Product } from "../models/product.model"
import { useDispatch } from "react-redux"
import { setProducts } from "../../../../redux/vendor/products/productsActions";
import FormData from 'form-data';
import axios from "../../../../interceptors/api-client";

export class ProductsService {
    dispatch = useDispatch()
    products: Product[] = []
    
      async getProducts() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products`,
          };

          const response = await axios.request(config)
          this.products = response.data.data
          this.dispatch(setProducts(response.data.data))
      }


      async getProduct(productId: string): Promise<Product> {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products/${productId}`,
          };

          const response = await axios.request(config)
          const product = new Product()
          return product.prepareProduct(response.data)
    }

      async createProduct(product: Product, file) {
          let formData = new FormData();
          formData.append('image', file);
          formData.append('productData', JSON.stringify(product));
          
          let config = {
              method: 'post',
              url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products`,
              headers: { 
                'Content-Type': 'multipart/form-data'
              },
              data: formData
          };
      
          try {
            return await axios.request(config);
          } catch (error) {
              console.error('Error inserting product:', error);
          }
      }


      async patchProduct(product: Product, file) {
          let formData = new FormData();
          formData.append('image', file);
          formData.append('productData', JSON.stringify(product));
          
          let config = {
              method: 'patch',
              url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products/${product.id}`,
              headers: { 
                'Content-Type': 'multipart/form-data'
              },
              data: formData
          };
      
          try {
              return await axios.request(config);
          } catch (error) {
              console.error('Error inserting product:', error);
          }
      }


    async getProductsFilters() {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products/specifics/all`
      };

      const response = await axios.request(config)
      const specifics = response.data
      return specifics
    }

    async deleteProduct(product) {
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/products/${product.id}`
      };

      await axios.request(config)
    }

}