import { useRef, useState } from 'react';
import { Alert, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../common/components/PageContainer';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as _ from "lodash";
import { SpacesService } from '../../../modules/vendor/my-spaces/services/spaces.service';
import { Add } from '@mui/icons-material';
import { base64ToBlob, getBase64 } from '../../../common/utils/utils';
import { setLoading } from '../../../redux/vendor/ui/uiActions';

export const NewSpace = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const spaceImageInputRef = useRef(null)
    const [spacesService] = useState<SpacesService>(new SpacesService());
    const [error, setError] = useState<boolean>(false)

    const pickSpaceImageHandler = (event) => {
        spaceImageInputRef.current.click();
    };

    const handleSpaceImageChangeChange = async(event) => {
        dispatch(setLoading(true))
        const file = event.target.files[0];
        const path = await getBase64(file);
        const blob = base64ToBlob(path.split(',')[1])
        const fileName = "image.png"
        const blobFile = new File([blob], fileName, { type: 'image/png' });
        const space = await spacesService.runAnalysis(blobFile)
        if(space && space.id)
            setTimeout(() => {
                navigate(`/spaces/${space.id}/edit`, {replace: true})
            }, 2000);
        else setError(true)
        dispatch(setLoading(false))
    };

    return (
        <PageContainer title={'Create new Space'}>
            <Grid container spacing={2} flex={2} padding={2}>
                <Grid item md={12} xs={12} >
                    <Box bgcolor={"secondary.main"} padding={2} borderRadius={3} position={"relative"} 
                            display={"flex"} 
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{cursor: "pointer"}}
                            onClick={pickSpaceImageHandler}
                            flexDirection={"row"} marginBottom={2}>
                        <Add sx={{ color: "white" }} />
                        <Typography fontSize={14} color={"white"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} >Select space image</Typography>
                    </Box>
                    <input
                        type="file"
                        ref={spaceImageInputRef}
                        name="image"
                        onChange={handleSpaceImageChangeChange}
                        style={{ display: 'none' }}
                    />
                </Grid>
                {error && <Alert severity="error" sx={{marginTop: 2}}>An error occured while analysing the space.</Alert>}
            </Grid>
        </PageContainer>
    );
};

