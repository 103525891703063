import { useDispatch } from "react-redux"
import Config from "../models/config.model";
import axios from "../../../../interceptors/api-client";

const fonts = ["Lexend", "Roboto", "Open Sans", "Montserrat", "Lato", "Poppins", "Source Sans Pro", "Raleway", "Noto Sans", "Inter", "Roboto Slab", "Merriweather", "Playfair Display"]

export class SettingsService {
    dispatch = useDispatch()
    static settings: Config

    static getCompanyConfig = () => {
      return this.settings
    }

    async getCompanyTheme() {
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/config`
        };

        const response = await axios.request(config)
        const companyConfig: Config = response.data
        SettingsService.settings = companyConfig
        this.setVendorAreas()
        return companyConfig
    }

    async patchCompanyTheme(companyConfig) {
      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/config`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : companyConfig
      };
      await axios.request(config)
    }

    async setVendorAreas() {
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/vendors/areas`
        };

        const response = await axios.request(config)
        SettingsService.settings.areas = response.data
    }
    
    async getFonts() {
      return fonts
    }

}