// TYPES
import HardwoodIcon from        "../../../../assets/icons/products/hardwood-icon.png"
import LaminateIcon from        "../../../../assets/icons/products/laminate-icon.png"
import TileIcon from            "../../../../assets/icons/products/tile-icon.png"
import CarpetingIcon from       "../../../../assets/icons/products/carpeting-icon.png"
import VinylIcon from           "../../../../assets/icons/products/vinyl-icon.png"
import StonesIcon from          "../../../../assets/icons/products/stones-icon.png"
// PATTERNS
import HerringboneIcon              from "../../../../assets/icons/products/patterns/herringbone-icon.png"
import DoubleHerringboneIcon        from "../../../../assets/icons/products/patterns/double-herringbone-icon.png"
import ChevronIcon                  from "../../../../assets/icons/products/patterns/chevron-icon.png"
import WoodStripIcon                from "../../../../assets/icons/products/patterns/wood-strip-icon.png"
import BrickBondIcon                from "../../../../assets/icons/products/patterns/brick-bond-icon.png"
import StackBondIcon                from "../../../../assets/icons/products/patterns/stack-bond-icon.png"
import CheckerboardIcon             from "../../../../assets/icons/products/patterns/checkerboard-icon.png"
import MosaicIcon                   from "../../../../assets/icons/products/patterns/mosaic-icon.png"

class Pattern {
    name: string
    icon
    constructor(name, icon){
        this.name = name
        this.icon = icon
    }
}

class Type {
    name: string
    icon
    patterns: Pattern[]
    constructor(name: string, icon){
        this.name = name
        this.icon = icon
        this.patterns = []
    }

    addPattern = (patternName: string, patternIcon) => {
        this.patterns.push(new Pattern(patternName, patternIcon))
        return this
    }
}

export const PRODUCT_TYPES_PATTERNS = {
    floor: [
        (new Type("hardwood", HardwoodIcon))
                                            .addPattern("bricks", BrickBondIcon)
                                            .addPattern("herringbone", HerringboneIcon)
                                            .addPattern("double herringbone", DoubleHerringboneIcon)
                                            .addPattern("wood strip", WoodStripIcon)
                                            .addPattern("chevron", ChevronIcon)
                                            .addPattern("stack bond", StackBondIcon)
                                            .addPattern("mosaic", MosaicIcon)
                                            .addPattern("checkerboard", CheckerboardIcon),
        (new Type("tile", TileIcon))
                                            .addPattern("mosaic", MosaicIcon)
                                            .addPattern("checkerboard", CheckerboardIcon),
        (new Type("carpeting", CarpetingIcon))
    ],
    walls: [
        (new Type("hardwood", HardwoodIcon))
                                            .addPattern("bricks", BrickBondIcon)
                                            .addPattern("herringbone", HerringboneIcon)
                                            .addPattern("double herringbone", DoubleHerringboneIcon)
                                            .addPattern("wood strip", WoodStripIcon)
                                            .addPattern("chevron", ChevronIcon)
                                            .addPattern("stack bond", StackBondIcon)
                                            .addPattern("mosaic", MosaicIcon)
                                            .addPattern("checkerboard", CheckerboardIcon),
        (new Type("tile", TileIcon))
                                            .addPattern("mosaic", MosaicIcon)
                                            .addPattern("checkerboard", CheckerboardIcon),
        (new Type("vinyl", VinylIcon)),
        (new Type("stones", StonesIcon))
    ]

} 

