import React, { useEffect, useState } from 'react';
import { Typography, Container,  TextField, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SpacesService } from '../../../modules/vendor/my-spaces/services/spaces.service';
import { useTranslation } from 'react-i18next';
import { SpacesAsGrid } from '../../../modules/vendor/my-spaces/components/spaces-as-grid.component';
import { toggleConfirmModal } from '../../../redux/vendor/ui/uiActions';
import ConfirmDialog from '../../../common/components/ConfirmModal';
import { PageContainer } from '../../../common/components/PageContainer';
import { TopNavBar } from '../../../common/Header/TopNavBar';
import { MainHeight, TopNavBarHeight } from '../../../assets/constants.css';
import { Route, Router, Routes, useLocation } from 'react-router-dom';
import { SpaceDetails } from '../../../modules/vendor/my-spaces/components/space-details.component';
import { VendorState } from '../../../redux/vendor/rootReducer';
import { setMaxSpaces, setSpaces } from '../../../redux/vendor/spaces/spacesActions';

export const MySpaces = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch()
    const location = useLocation()

    const { spaces, maxSpaces } = useSelector((state: VendorState) => state.spaces);
    const [spacesService] = useState<SpacesService>(new SpacesService());
    const [filteredSpaces, setFilteredSpaces] = useState(spaces);
    const [selectedSpaces, setSelectedSpaces] = useState([]);
    const [filter, setFilter] = useState('');
    const [modalProps, setModalProps] = useState({})

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        const allSpaces = await spacesService.getSpaces();
        dispatch(setSpaces(allSpaces.data))
        dispatch(setMaxSpaces(allSpaces.maxSpaces))
    }

    useEffect(() => {
        setFilteredSpaces(spaces);
    }, [spaces]);

    const handleValidateSelection = () => {
        dispatch(toggleConfirmModal())
        setModalProps({
            title: t("PAGES.MY_SPACES.CONFIRM.VALIDATE_TITLE"),
            description: t("PAGES.MY_SPACES.CONFIRM.VALIDATE_DESCRIPTION"),
            handleAccepted: () => spacesService.assignSpaces(selectedSpaces)
        })
    }

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilter(value);
        const filteredSpaces = spaces.filter(space =>
            space.spaceType.toLowerCase().includes(value.toLowerCase()) ||
            space.surfaces.some(type => type.toLowerCase().includes(value.toLowerCase()))
        );
        setFilteredSpaces(filteredSpaces);
    };

    return (
        <PageContainer title={t('PAGES.MY_SPACES.TITLE')}>
            <Box display="flex" alignItems={"center"}>
                <TextField
                    label={
                        <Typography variant='caption' sx={{fontSize: "10pt", letterSpacing: -0.5}}> {t('PAGES.MY_SPACES.FILTER_TEXT')} </Typography>
                        }
                    variant="outlined"
                    value={filter}
                    onChange={handleFilterChange}
                    sx={{marginBottom: 2, marginRight: 2, height: "40px", fontSize: "10pt", letterSpacing: -0.5}}
                    fullWidth
                    size='small'
                />
            </Box>
            <Box height={`calc(${MainHeight} - ${TopNavBarHeight} - 180px)`} overflow={"auto"}>
                <SpacesAsGrid   spaces={spaces}
                                maxSpaces={maxSpaces}
                                selectedSpaces={selectedSpaces}
                                setSelectedSpaces={setSelectedSpaces}
                                filteredSpaces={filteredSpaces} />               
               
            </Box>
            
            <Box display="flex" justifyContent="flex-end" mt={2}>
                <Box display={"flex"} alignItems={"center"} onClick={handleValidateSelection}
                    sx={{cursor: "pointer", bgcolor: "primary.text", paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content"}}>
                    <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>{t('PAGES.MY_SPACES.VALIDATE_SELECTION')}</Typography>
                </Box>
            </Box>
            <ConfirmDialog {...modalProps} />
        </PageContainer>
    );
};

export default MySpaces;
