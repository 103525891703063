import { jwtDecode } from 'jwt-decode';
import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setUser } from '../redux/auth/authActions';
import { AuthState } from '../redux/vendor/rootReducer';
import { SettingsService } from '../modules/vendor/settings/services/settings.service';
import Config from 'chart.js/dist/core/core.config';

export const authContext = createContext<any>({});

const AuthProvider = ({ children }) => {
    const [hasAccess, setHasAccess] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const {user} = useSelector((state: AuthState) => state.auth);
    const dispatch = useDispatch()
    const location = useLocation(); 
    const [settingsService] = useState<SettingsService>(new SettingsService())
    const [vendorSettings, setVendorSettings] = useState<any>()

    const checkAccess = async() => {
        if(user && user.access_token){
            const decoded = jwtDecode<any>(user.access_token); 
            if(decoded.exp*1000 < new Date().getTime()){
                setHasAccess(false);
                dispatch(setUser(null));
            } else {
                setHasAccess(true);
                const roles = decoded.roles
                if(roles && roles[0] === "SUPER_ADMIN"){
                    setIsSuperAdmin(true)
                } 
            }
        } 
        else {
            setHasAccess(false);
            setIsSuperAdmin(false);
        }
    }

    useEffect(() => {
        if(user && !isSuperAdmin && hasAccess){
            fetchVendorConfig()
        }
    }, [user])

    const fetchVendorConfig = async() => {
        const companyConfig = await settingsService.getCompanyTheme()
        setVendorSettings(companyConfig)
    }

    useEffect(() => {
        checkAccess()
    }, [user, location])

    return (
        <authContext.Provider value={{ hasAccess, isSuperAdmin, checkAccess, vendorSettings }}>
            {children}
        </authContext.Provider>
    );
};

export default AuthProvider;