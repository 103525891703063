import { Vendor } from "../models/vendor.model"
import { useDispatch } from "react-redux"
import FormData from 'form-data';
import axios from "../../../../interceptors/api-client";

export class VendorsService {
    dispatch = useDispatch()
    
      async getVendors() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/vendors`,
          };

          const response = await axios.request(config)
          return response.data
      }


      async getVendor(vendorId: string) {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/vendors/${vendorId}`,
          };

          const response = await axios.request(config)
          return response.data
      }

    async createVendor(vendor: Vendor) {
      if(vendor.spaces){
        //@ts-ignore
        vendor.spaces = vendor.spaces.map(space => space._id)
      }
      let config = {
          method: 'post',
          url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/vendors`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data: vendor
      };
      try {
        return await axios.request(config);
      } catch (error) {
          console.error('Error inserting vendor:', error);
          throw error
      }
    }


    async patchVendor(vendorId: string, vendor: Vendor) {
      if(vendor.spaces){
        //@ts-ignore
        vendor.spaces = vendor.spaces.map(space => space._id)
      }
      let config = {
        method: 'patch',
        url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/vendors/${vendorId}`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data: vendor
      };
      try {
        return await axios.request(config);
      } catch (error) {
          console.error('Error inserting vendor:', error);
      }
    }

    async deleteVendor(vendor) {
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/vendors/${vendor.uuid}`
      };

      await axios.request(config)
    }

}