import { Box, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { VendorsService } from "../../our-vendors/services/vendors.service";
import { useParams } from "react-router-dom";


export const UserChangePassword = ({user, setUser, check}) => {
    const [t,] = useTranslation();
    const [vendorsService] = useState(new VendorsService())
    let { userId } = useParams();

    const [fields, setFields] = useState({
        password: {
            isTouched: false,
            label: "New Password",
            name: "password"
        }
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            isTouched: true
        }})
        setUser({ ...user, [name]: value })
    }

    const isValidInput = (name) => {
        return (check && !user[name]) || (fields && fields[name] && fields[name].isTouched && !user[name])
    }

    return <Box bgcolor={"primary.light"}  padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"} marginTop={1}>
    <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>Password reset</Typography>
    <Divider />
    <Grid container columnSpacing={3} rowSpacing={1} marginTop={1}>
        {userId && <Grid item md={12} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.password.label}</Typography>
            <TextField
                    error={isValidInput(fields.password.name)}
                    name={fields.password.name}
                    value={user?user.password:""}
                    onChange={handleChange}
                    type="password"
                    fullWidth
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>}
    </Grid>
</Box>
}