import { SET_USER } from "./authActionTypes";

export interface RoomsState {
    user
}

const initialState: RoomsState = {
    user: undefined
};

const authReducer = (state = initialState, action): RoomsState => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload
            }
        default:
            return state
    }
};

export default authReducer;