import { useEffect, useRef, useState } from "react";
import { Chart, Line } from "react-chartjs-2"
import { AnalyticsService } from "../../settings/services/analytics.service";
import { fillMissingDates } from "../../../../common/utils/utils";
import FadeIn from "react-fade-in";
import { ProductsService } from "../../my-products/services/products.service";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { Tune } from "@mui/icons-material";
import { ProductView } from "../../my-products/components/product-view.component";

export const ProductUsageAnalytics = ({startDate, endDate}) => {
    const [productsUsageData, setProductsUsageData] = useState([])
    const [surfacesUsageData, setSurfacesUsageData] = useState([])
    const [labels, setLabels] = useState()
    const [productUsage, setProductUsage] = useState([])
    const [surfaceUsage, setSurfaceUsage] = useState([])
    const [analyticsService] = useState<AnalyticsService>(new AnalyticsService());
    const [productsService] = useState<ProductsService>(new ProductsService());
    const chartRef = useRef(null);
    const [highlightedIndex, setHighlightedIndex] = useState(null);
    const [productsToDisplay, setProductsToDisplay] = useState([])

    const [selectedProductForView, setSelectedProductForView] = useState(undefined)

    const [fullProductsToDisplay, setFullProductsToDisplay] = useState([])

    const getProductUsageAnalytics = async () => {
        let data = await analyticsService.queryAnalytics({
            statType: "PRODUCT_USAGE_COUNT",
            startDate: startDate,
            endDate: endDate
        })
        data = fillMissingDates(data)
        setProductsUsageData(data)
        setLabels(data.map(e => e._id))
        setProductUsage(data.map(e => e.count))
    }

    const getSurfacesUsageAnalytics = async () => {
      let data = await analyticsService.queryAnalytics({
          statType: "SURFACE_USAGE_COUNT",
          startDate: startDate,
          endDate: endDate
      })
      data = fillMissingDates(data)
      setSurfacesUsageData(data)
      setLabels(data.map(e => e._id))
      setSurfaceUsage(data.map(e => e.count))
    }

    const getProducts = async () => {
      if(productsToDisplay && productsToDisplay.length > 0){
        const promises = []
        productsToDisplay.forEach(async(product) => {
            promises.push(productsService.getProduct(product.productId))
        })
        const data = await Promise.all(promises)
        setFullProductsToDisplay(data)
      } else {
        setFullProductsToDisplay([])
      }
    }

    useEffect(() => {
      getProductUsageAnalytics()
      getSurfacesUsageAnalytics()
    }, [startDate, endDate])

    useEffect(() => {
      getProducts()
    }, [productsToDisplay])

    useEffect(() => {
      const chartInstance = chartRef.current;
      if (chartInstance) {
        const canvas = chartInstance.canvas;
        const handleClick = (event) => {
          const points = chartInstance.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
          if (points.length) {
            const firstPoint = points[0];
            setHighlightedIndex(firstPoint.index);
          } else {
            setHighlightedIndex(null);
          }
        };
        canvas.addEventListener('click', handleClick);
        return () => {
          canvas.removeEventListener('click', handleClick);
        };
      }
    }, []);
  
    useEffect(() => {
      const chartInstance = chartRef.current;
      if (chartInstance) {
        setProductsToDisplay(highlightedIndex >=0 ? productsUsageData[highlightedIndex]?.products:[])
        chartInstance.update();
      }
    }, [highlightedIndex]);

    const getVisitsCount = (product) => {
      return productsUsageData[highlightedIndex]?.products?.find(p => p.productId === product.id)?.usageCount
    }

    return <FadeIn>
      <Chart 
        type="line"
        ref={chartRef}
        style={{"width": "100%"}}
        data={{
            labels: labels,
            datasets: [
                {
                  label: 'Products used',
                  data: productUsage,
                  fill: false,
                  backgroundColor: (context) => {
                    return context.dataIndex === highlightedIndex ? '#FFFFFF' : 'orange';
                  },
                  borderColor: 'orange',
                  borderWidth: (context) => {
                    return context.dataIndex === highlightedIndex ? 4 : 2;
                  },
                  tension: 0.1,
                  pointRadius: (context) => {
                    return context.dataIndex === highlightedIndex ? 6 : 4;
                  },
                  stack: 'Stack 1',
                  
                },
                {
                  label: 'Floor',
                  data: surfacesUsageData.map(usage => ({
                      ...usage,
                      surfaces: usage.surfaces?.filter(surface => surface.selectedSurface === "floor")
                    })).map(usage => usage.surfaces ? (usage.surfaces[0]?usage.surfaces[0].usageCount:0) : 0),
                    backgroundColor: '#217EE8',
                  borderColor: '#217EE8',
                  borderWidth: 2,
                  type: 'bar',
                  stack: 'Stack 0',

                },
                {
                  label: 'Walls',
                  data: surfacesUsageData.map(usage => ({
                      ...usage,
                      surfaces: usage.surfaces?.filter(surface => surface.selectedSurface === "walls")
                    })).map(usage => usage.surfaces ? (usage.surfaces[0]?usage.surfaces[0].usageCount:0) : 0),
                  backgroundColor: '#0C2DA2',
                  borderColor: '#0C2DA2',
                  borderWidth: 2,
                  type: 'bar',
                  stack: 'Stack 0',
                },
                {
                  label: 'Ceiling',
                  data: surfacesUsageData.map(usage => ({
                      ...usage,
                      surfaces: usage.surfaces?.filter(surface => surface.selectedSurface === "ceiling")
                    })).map(usage => usage.surfaces ? (usage.surfaces[0]?usage.surfaces[0].usageCount:0) : 0),
                    backgroundColor: 'pink',
                  borderColor: 'pink',
                  borderWidth: 2,
                  type: 'bar',
                  stack: 'Stack 0',
                }
              ],
              
        }} 
        options={{
            responsive: true,
            plugins: {
                tooltip: {
                    intersect: false
                },
                title: {
                    display: true,
                    text: 'Products used per day'
                }
                },
                hover: {
                    mode: 'nearest',
                },
                scales: {
                    x: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Day'
                        }
                    },
                    y: {
                      stacked: true,
                      title: {
                          display: true,
                          text: 'Usages'
                      },
                      min: 0,
                      ticks: {
                          stepSize: 1
                      }
                    }
                }
        }}/>
      <Box>
        {fullProductsToDisplay && fullProductsToDisplay.map(product => 
          <Box marginBottom={2} display={"flex"} sx={{backgroundColor: "primary.main", padding: 1, borderRadius: 4}}>
            <img src={product.thumbnailPath} width={"100px"} style={{border: "2px solid lightgray", borderRadius: "8px", cursor: "pointer"}}
              onClick={() => setSelectedProductForView(product.id)}
            />
            <Box flex={2} paddingLeft={3}>

              <Typography color='primary.text' textTransform="capitalize" 
                sx={{fontSize: "9pt", letterSpacing: -0.5}}>{product.brand}</Typography>
              <Typography color='primary.text' textTransform="capitalize" 
                sx={{fontSize: "10pt", letterSpacing: -0.5}} fontWeight={"bolder"}>{product.name}</Typography>

              <Typography color='primary.text' textTransform="capitalize" 
                sx={{fontSize: "10pt", letterSpacing: -0.5}}>Color: {product.color}</Typography>
              <Typography color='primary.text' textTransform="capitalize" 
                sx={{fontSize: "10pt", letterSpacing: -0.5}}>Size: {product.sizeName}</Typography>
              <Typography color='primary.text' textTransform="capitalize" 
                sx={{fontSize: "10pt", letterSpacing: -0.5}}>Pattern: {product.pattern}</Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"} flexDirection={"column"} justifyContent={"center"}>
              <Typography color='primary.text' 
                sx={{fontSize: "10pt", letterSpacing: -0.5, marginBottom: -1.5}}>
                  Used
              </Typography>
              <Typography color='primary.text' fontWeight={"bolder"}
                sx={{fontSize: "28pt", letterSpacing: -0.5}}>
                  {getVisitsCount(product)}
              </Typography>
              <Typography color='primary.text' 
                sx={{fontSize: "10pt", letterSpacing: -0.5, marginTop: -1.5}}>
                  time{`${getVisitsCount(product) === 1 ? "":"s"}`}
              </Typography>
            </Box>
            {selectedProductForView && <ProductView selectedProductForViewID={selectedProductForView} setSelectedProductForView={setSelectedProductForView} />}
          </Box>
        )}
      </Box>
      </FadeIn>
}