import { useDispatch } from "react-redux"
import { setMaxSpaces, setSpaces } from "../../../../redux/vendor/spaces/spacesActions";
import { Space } from "../models/space.model";
import axios from "../../../../interceptors/api-client";

export class SpacesService {
    dispatch = useDispatch()
    spaces: Space[] = []
    maxSpaces: number
    
    async getSpaces() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/spaces`
          };

          const response = await axios.request(config)
          return response.data
    }

    async createSpace(space: Space) {
      let config = {
          method: 'post',
          url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/spaces`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data: space
      };
      try {
        return await axios.request(config);
      } catch (error) {
          console.error('Error inserting space:', error);
      }
    }

    async patchSpace(space: Space) {
      let config = {
          method: 'patch',
          url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/spaces/${space.id}`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data: space
      };
      try {
        return await axios.request(config);
      } catch (error) {
          console.error('Error updating space:', error);
      }
    }

    async runAnalysis(spaceFile) {
      let formData = new FormData();
      formData.append('image', spaceFile);

      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_PY_API_URL}/analysis`,
        headers: { 
          'Content-Type': 'multipart/form-data',
          'Device-Token': "111"
        },
        data: formData,
        
    };
    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
        console.error('Error updating space:', error);
    }
    }

    async patchMasks(space: Space, maskFile) {
      let formData = new FormData();
          formData.append('mask', maskFile);
          
          let config = {
              method: 'put',
              url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/spaces/${space.id}/masks`,
              headers: { 
                'Content-Type': 'multipart/form-data'
              },
              data: formData
          };
      
          try {
            return await axios.request(config);
          } catch (error) {
              console.error('Error inserting product:', error);
          }
    }

    async getSpace(spaceId) {
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/spaces/${spaceId}`
        };

        const response = await axios.request(config)
        return response.data
    }

    async deleteSpace(spaceId) {
    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/spaces/${spaceId}`
      };

      const response = await axios.request(config)
      return response.data
    }
    
    async assignSpaces(spaces) {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/spaces/assign`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : spaces.map(space => space.id)
      };
      await axios.request(config)
    }

}

