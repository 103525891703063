import {RESET_THEME, SET_INITIAL_THEME} from "./settingsActionTypes";
import {SET_LOGO, SET_VENDOR_NAME, SET_PRIMARY_COLOR,          SET_SECONDARY_COLOR,        SET_FONT,       SET_LANGUAGE} from "./settingsActionTypes";

export const setPrimaryColor = (color) => {
    return {
        type: SET_PRIMARY_COLOR,
        payload: color
    };
};

export const setSecondaryColor = (color) => {
    return {
        type: SET_SECONDARY_COLOR,
        payload: color
    };
};

export const setFont = (font) => {
    return {
        type: SET_FONT,
        payload: font
    };
};

export const setLanguage = (lng) => {
    return {
        type: SET_LANGUAGE,
        payload: lng
    };
};

export const setLogo = (fonts) => {
    return {
        type: SET_LOGO,
        payload: fonts
    };
};
export const setCompanyName = (name) => {
    return {
        type: SET_VENDOR_NAME,
        payload: name
    };
};

export const resetTheme = () => {
    return {
        type: RESET_THEME
    };
};

export const setInitialTheme = () => {
    return {
        type: SET_INITIAL_THEME
    };
};
