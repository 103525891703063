
import { Box, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export const SpaceGeneralInformation = ({space, setSpace, submitted, setIsValid}) => {
    const [t,] = useTranslation();
    const areas = ["floor", "walls", "Ceiling", "interactive_floor"]

    const [fields, setFields] = useState({
        name: {
            isTouched: false,
            label: "Name*",
            name: "name"
        },
        areas: {
            isTouched: false,
            label: "Areas*",
            name: "areas"
        }
    })

    useEffect(() => {
        if(space)
            checkValidity()
    }, [space])


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            isTouched: true
        }})
        setSpace({ ...space, [name]: value })
    }

    const handleToggleArea = (surface) => {
        if(space.surfaces.includes(surface)){
            setSpace({ 
                ...space, 
                surfaces: space.surfaces.filter(va => va !== surface )
            })
        }
        else 
            setSpace({ 
                ...space, 
                surfaces: [...(space.surfaces??[]), surface]
            })
    }

    const isValidInput = (name) => {
        return (submitted && !space[name]) || (fields && fields[name].isTouched && !space[name])
    }


    const checkValidity = () => {
        setIsValid(space.name && space.surfaces && space.surfaces.length > 0);
    };

    return <Box bgcolor={"primary.light"}  padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"}>
    <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.TITLE")}</Typography>
    <Divider />
    <Grid container columnSpacing={3} rowSpacing={1} marginTop={1}>
        <Grid item md={12} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.name.label}</Typography>
            <TextField
                    error={isValidInput(fields.name.name)}
                    name={fields.name.name}
                    value={space?space.name || "":""}
                    onChange={handleChange}
                    fullWidth
                    color="secondary"
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>
        <Grid item md={12} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.areas.label}</Typography>
                <Box display={"flex"}>
                {areas?.map((area, index) => (<Box key={index} bgcolor={space.surfaces?.find(e => e.toLowerCase() === area.toLowerCase()) ? "secondary.main":"primary.main"} paddingInline={2} 
                                    paddingBlock={1} marginInline={1} borderRadius={2} 
                                    onClick={() => handleToggleArea(area)}
                                    sx={{cursor: "pointer"}}>
                        <Typography fontSize={14} textTransform={"capitalize"} color={space.surfaces?.find(e => e.toLowerCase() === area.toLowerCase())?"white":"primary.text"} letterSpacing={0} >{area}</Typography>
                    </Box>)
                )}
                </Box>
        </Grid>
    </Grid>
</Box>
}