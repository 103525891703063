import React, { useEffect, useState } from 'react';
import { Typography, Container,  TextField, Button, Box, useMediaQuery, ToggleButtonGroup, ToggleButton, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../common/components/ConfirmModal';
import { ViewList, ViewModule } from '@mui/icons-material';
import { TopNavBar } from '../../../common/Header/TopNavBar';
import { PageContainer } from '../../../common/components/PageContainer';
import { SpacesService } from '../../../modules/vendor/my-spaces/services/spaces.service';
import { setLoading, toggleConfirmModal } from '../../../redux/vendor/ui/uiActions';
import { Space } from '../../../modules/vendor/my-spaces/models/space.model';
import { SpacesAsList } from '../../../modules/superadmin/our-spaces/components/spaces-as-list.component';
import { SpacesAsGrid } from '../../../modules/vendor/my-spaces/components/spaces-as-grid.component';

export const OurSpaces = () => {
    const [t] = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [spacesService] = useState<SpacesService>(new SpacesService());
    const [displayedSpaces, setDisplayedSpaces] = useState([]);
    const [filteredSpaces, setFilteredSpaces] = useState(displayedSpaces);
    const [filter, setFilter] = useState('');
    const [viewMode, setViewMode] = useState<'list' | 'grid'>('list'); // Track view mode
    const [modalProps, setModalProps] = useState({})

    useEffect(() => {
        fetchSpaces()
    }, [])

    const fetchSpaces = async () => {
        dispatch(setLoading(true))
        const spaces = await spacesService.getSpaces()
        setDisplayedSpaces(spaces.data)
        dispatch(setLoading(false))
    }

    const onDelete = (space) => {
        dispatch(toggleConfirmModal())
        setModalProps({
            title: "Remove space",
            description: "Do you really wan't to remove this space?",
            handleAccepted: () => handleRemoveSpace(space)
        })
    }

    const handleRemoveSpace = async(space) => {
        await spacesService.deleteSpace(space.id)
        await fetchSpaces()
    }

    useEffect(() => {
        setFilteredSpaces(displayedSpaces);
    }, [displayedSpaces]);


    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilter(value);
        const filteredSpaces = displayedSpaces.filter(space =>
            space._id.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            space.id.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            space.name.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            space.surfaces.some(area => area.replace(/ {2,}/g, " ").replace('_',' ').toLowerCase().includes(value.toLowerCase()))
        );
        setFilteredSpaces(filteredSpaces);
    };

    const handleEditSpace = (space: Space) => {
        navigate(`/spaces/${space.id}/edit`)
    }

    const toggleViewMode = (mode: 'list' | 'grid') => {
        setViewMode(mode === 'list' ? 'grid' : 'list');
    };

    return (
        <PageContainer title={"Spaces list"}>
                <Box display="flex">
                    <TextField
                            label={
                                <Typography variant='caption' sx={{fontSize: "10pt", letterSpacing: -0.5}}> Filter by name, alias, email or areas </Typography>
                              }
                            variant="outlined"
                            color='secondary'
                            value={filter}
                            onChange={handleFilterChange}
                            sx={{marginBottom: 2, marginRight: 2, height: "40px", fontSize: "10pt", letterSpacing: -0.5}}
                            fullWidth
                            size='small'
                        />
                        
                    <ToggleButtonGroup
                            value={viewMode}
                            exclusive
                            aria-label="text alignment"
                            sx={{marginBottom: 2, height: "40px"}}
                        >
                            <ToggleButton value="list" aria-label="list" onClick={(mode) => toggleViewMode('grid')}>
                                <ViewList />
                            </ToggleButton>
                            <ToggleButton value="grid" aria-label="grid" onClick={(mode) => toggleViewMode('list')}>
                                <ViewModule />
                            </ToggleButton>


                    </ToggleButtonGroup>
                    
                </Box>
                
                {viewMode === 'list' ? (
                        <SpacesAsList spaces={displayedSpaces}
                                        filteredSpaces={filteredSpaces}
                                        setFilteredSpaces={setFilteredSpaces}
                                        handleEditSpace={handleEditSpace}
                                        onDelete={onDelete}
                        />
                ) : (
                    <SpacesAsGrid   spaces={displayedSpaces}
                                    maxSpaces={3}
                                    selectedSpaces={[]}
                                    setSelectedSpaces={() => {}}
                                    handleSelectedSpaceForDeletion={() => {}}
                                    onDelete={onDelete}
                                    handleEditSpace={handleEditSpace}
                                    filteredSpaces={displayedSpaces} />
                )}
                <ConfirmDialog {...modalProps} />
            
        </PageContainer>
    );
};

export default OurSpaces;
