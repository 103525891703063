import React, { useEffect, useRef, useState } from 'react';
import { Alert, Box, Divider, Grid, Skeleton, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../common/components/PageContainer';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as _ from "lodash";
import { Edit } from '@mui/icons-material';
import { SpacesService } from '../../../modules/vendor/my-spaces/services/spaces.service';
import { Space } from '../../../modules/vendor/my-spaces/models/space.model';
import { setLoading } from '../../../redux/vendor/ui/uiActions';
import { base64ToBlob, getBase64 } from '../../../common/utils/utils';
import { SpaceGeneralInformation } from '../../../modules/superadmin/edit-space/components/space-general-information.form';
import { SpacesSurfaces } from '../../../modules/superadmin/edit-space/components/space-surfaces.form';

export const EditSpace = () => {
    const [t,] = useTranslation();
    const [spacesService] = useState<SpacesService>(new SpacesService());
    const [space, setSpace] = useState<Space>(new Space())
    const [submitted, setSubmitted] = useState<boolean>(false)
    let { spaceId } = useParams();
    const [refreshKey, setRefreshKey] = useState(0);
    const [isStep1Valid, setIsStep1Valid] = useState(true);
    const [isStep2Valid, setIsStep2Valid] = useState(true);
    const [isValid, setIsValid] = useState(true);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [initialSpace, setInitialSpace] = useState<any>()

    useEffect(() => {
        setIsValid(isStep1Valid && isStep2Valid)
    }, [isStep1Valid, isStep2Valid])

    useEffect(() => {
        dispatch(setLoading(true))
        if(spaceId){
            setTimeout(() => {
                setSpaceForEdit()
                dispatch(setLoading(false))
            }, 1000);
        } else {
            setSpace(new Space())
                dispatch(setLoading(false))
        }
        handleRefresh()
    }, [spaceId])


    const handleRefresh = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };

    const setSpaceForEdit = async () => {
        const space = await spacesService.getSpace(spaceId)
        setSpace(space)
        setInitialSpace(space)
    }

    const handleSave = async () => {
        setSubmitted(true)
        
        if(isValid){
            dispatch(setLoading(true))
            const body = _.cloneDeep(space)
            const initialSpaceClone = _.cloneDeep(initialSpace)
            delete body.mask
            delete initialSpaceClone.mask
            if(!spaceId)
                await spacesService.createSpace(body)
            else if(!_.isEqual(body, initialSpaceClone))
                await spacesService.patchSpace(body)

            dispatch(setLoading(false))
            //navigate("/spaces", {replace: true})
        }
    }

    return (
        <PageContainer title={t(spaceId ? 'Edit':'Add') +  ` space: ${space.name??""}`}>
            <Grid container spacing={2} flex={2} padding={2} key={refreshKey}>
                <Grid item md={12} xs={12}>
                    <SpaceGeneralInformation space={space} setSpace={setSpace} submitted={submitted} setIsValid={setIsStep1Valid}/>
                </Grid>
                <Grid item md={12} xs={12}>
                    {space && <SpacesSurfaces space={space} setSpace={setSpace} submitted={submitted} setIsValid={setIsStep2Valid} />}
                </Grid>
            </Grid>
            <Box    display="flex" justifyContent="flex-end" mb={2} 
                    marginBlock={1} position={"fixed"} bottom={25} right={40} height={"70px"} zIndex={1515615}>
                <Box display={"flex"} alignItems={"center"} 
                    onClick={() => handleSave()}
                    sx={{cursor: `${isValid ? "pointer": "not-allowed"}`, bgcolor: `${isValid ? "primary.text": "gray"}`, paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content"}}>
                    <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>{`${spaceId ? "Save changes":"Create space"}`}</Typography>
                </Box>
            </Box>
        </PageContainer>
    );
};

