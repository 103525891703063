import { Box, Typography, useTheme } from "@mui/material";
import { EditProduct } from "../../models/edit-product.model";

import * as _ from "lodash"

interface ProductTypeProps {
    editProduct: EditProduct
    setEditProduct,
    icon,
    type: string
}

export const ProductType = (props: ProductTypeProps) => {
    const theme = useTheme();

    const isSelected = () => {
        return props.type?.toLowerCase() === props.editProduct.type?.toLowerCase()
    }

    const handleTypeSelected = () => {
        props.setEditProduct(_.cloneDeep(props.editProduct.setType(props.type)))
    }

    return (<Box borderRadius={2} paddingBlock={1} border={"1px solid gray"}  onClick={handleTypeSelected} sx={{cursor: 'pointer'}}
        bgcolor={isSelected()?"secondary.main":"primary.main"} 
        display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
        <img src={props.icon} style={{height: "45px", padding: "10px", 
            filter: (theme.palette.mode === 'dark' || isSelected()) ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
        {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={isSelected()?"primary.main":"primary.contrastText"}>{props.type}</Typography>}
        {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.text"}>{props.type}</Typography>}
    </Box>)
}
