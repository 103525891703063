import { BrowserRouter } from 'react-router-dom'
import { AuthRouter } from './router/router';
import AuthProvider from './providers/AuthProvider';
import { createContext, useMemo, useState } from 'react';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
import ThemeWrapper from './common/components/ThemeWrapper';
Chart.register(CategoryScale);

/** Import main fonts */
//import './common/fonts/fonts.js'

declare module '@mui/material/styles' {
    interface PaletteColor {
      text?: string;
    }
  
    interface SimplePaletteColorOptions {
        text?: string;
    }
}

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
    const [mode, setMode] = useState<'light' | 'dark'>('light');
    const colorMode = useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      [],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <BrowserRouter>
                <AuthProvider>
                    <ThemeWrapper>
                        <AuthRouter />
                    </ThemeWrapper>
                </AuthProvider>
            </BrowserRouter>
        </ColorModeContext.Provider>

    );
}
export default App;