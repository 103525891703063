import React, { useEffect, useRef, useState } from 'react';
import { Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Container, Grid, FormControlLabel, RadioGroup, Radio, Box, Divider } from '@mui/material';
import { ChromePicker } from 'react-color';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { SketchPicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetTheme, setFont, setInitialTheme, setLanguage, setLogo, setPrimaryColor, setSecondaryColor} from '../../../redux/vendor/settings/settingsActions';
import { getBase64 } from '../../../common/utils/utils';
import { SettingsService } from '../../../modules/vendor/settings/services/settings.service';
import { PageContainer } from '../../../common/components/PageContainer';
import { Check, Close, Edit } from '@mui/icons-material';
import { VendorState } from '../../../redux/vendor/rootReducer';
import { setLoading } from '../../../redux/vendor/ui/uiActions';
import { useNavigate } from 'react-router-dom';

export const Design = () => {
    const { t, i18n } = useTranslation();
    const {theme, initialTheme} = useSelector((state: VendorState) => state.settings);

    const [tempPrimaryColor, setTempPrimaryColor] = useState(theme?.primaryColor)
    const [tempSecondaryColor, setTempSecondaryColor] = useState(theme?.secondaryColor)

    const [primaryColor, setLocalPrimaryColor] = useState(theme?.primaryColor)
    const [secondaryColor, setLocalSecondaryColor] = useState(theme?.secondaryColor)

    const [companyName, setLocalCompanyName] = useState(theme.vendorName);
    const [companyLogo, setLocalCompanyLogo] = useState(theme.logo);
    const [companyLanguage, setLocalCompanyLanguage] = useState(theme.language);
    const [preferredFont, setLocalPreferredFont] = useState(theme.font);

    const [isOnEditPrimaryColor, setIsOnEditPrimaryColor] = useState(false);
    const [isOnEditSecondaryColor, setIsOnEditSecondaryColor] = useState(false);
    
    const [fonts, setFonts] = useState<any>([])

    const logoInputRef = useRef(null)

    const [settingsService] = useState<SettingsService>(new SettingsService())
    const navigate = useNavigate()

    const changeLanguage = lng => {
        setLocalCompanyLanguage(lng)
        dispatch(setLanguage(lng))
        i18n.changeLanguage(lng);
    };

    const dispatch = useDispatch()

    useEffect(() => {
        const getFonts = async () => {
            const fonts = await settingsService.getFonts()
            setFonts(fonts)
        }
        getFonts()
        return ( ) => {
            dispatch(resetTheme())
            i18n.changeLanguage(initialTheme.language);
        }
    }, [])
    
    const setSelectedFont = (selectedFont) => {
        setLocalPreferredFont(selectedFont)
        dispatch(setFont(selectedFont))
    }

    const handlePrimaryColorChange = (color) => {
        setTempPrimaryColor(color.hex)
    };

    const validatePrimaryColor = () => {
        setLocalPrimaryColor(tempPrimaryColor)
        dispatch(setPrimaryColor(tempPrimaryColor))
        setIsOnEditPrimaryColor(false)
    }

    const handleSecondaryColorChange = (color) => {
        setTempSecondaryColor(color.hex)
    };

    const validateSecondaryColor = () => {
        setLocalSecondaryColor(tempSecondaryColor)
        dispatch(setSecondaryColor(tempSecondaryColor))
        setIsOnEditSecondaryColor(false)
    }

    const handleLanguageChange = (event) => {
        setLocalCompanyLanguage(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Handle form submission here
        const config = {
            language: [companyLanguage],
            logo: companyLogo,
            company: companyName,
            primaryColor: primaryColor,
            secondaryColor: secondaryColor
        }
        dispatch(setLoading(true))
        await settingsService.patchCompanyTheme(config)
        dispatch(setInitialTheme())
        dispatch(setLoading(false))
    };

    const pickImageHandler = (event) => {
        logoInputRef.current.click();
    };

    const handleCompanyLogoChange = async(event) => {
        const file = event.target.files[0];
        const path = await getBase64(file);
        setLocalCompanyLogo(path);
        dispatch(setLogo(path))
    };

    return ( 
        <PageContainer title={t('SIDEBAR.CONFIGURATION.DESIGN')}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Box bgcolor={"primary.light"} padding={2} margin={0} borderRadius={3} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"} >{t("PAGES.SETTINGS.FIELDS.COMPANY_NAME")}</Typography>
                                        <TextField
                                            value={companyName}
                                            onChange={(e) => setLocalCompanyName(e.target.value)}
                                            fullWidth
                                            required
                                            size='small'
                                            InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                                            />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth margin="none">
                                            <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"} >{t("PAGES.SETTINGS.FIELDS.PREFERRED_LANGUAGES")}</Typography>
                                            <Select
                                                value={companyLanguage}
                                                onChange={handleLanguageChange}
                                                inputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                                                size='small'
                                            >
                                                <MenuItem value="fr" onClick={() => changeLanguage('fr')} sx={{  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" }}>🇫🇷 Français</MenuItem>
                                                <MenuItem value="en" onClick={() => changeLanguage('en')} sx={{  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" }}>🇬🇧 English</MenuItem>
                                                <MenuItem value="es" onClick={() => changeLanguage('es')} sx={{  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" }}>🇪🇸 Española</MenuItem>
                                                <MenuItem value="ar" onClick={() => changeLanguage('ar')} sx={{  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" }}>🇸🇦 العربية</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth margin="none">
                                            <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"} >{t("PAGES.SETTINGS.FIELDS.PREFERRED_FONT")}</Typography>
                                            <Select
                                                value={preferredFont}
                                                onChange={(e) => setSelectedFont(e.target.value)}
                                                inputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                                                size='small'
                                                >
                                                {fonts?.map(font => 
                                                    <MenuItem value={font} sx={{  backgroundColor: "primary.main" }}><Typography fontFamily={font} sx={{color: "primary.contrastText", fontSize: "10pt"}}>{font}</Typography></MenuItem>
                                                )}
                                                
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"} >{t("PAGES.SETTINGS.FIELDS.PRIMARY_COLOR")}</Typography>
                                        <FormControl fullWidth margin="dense" >
                                            {!isOnEditPrimaryColor && <Box bgcolor={primaryColor} padding={2} height={40} borderRadius={2} display={"flex"} justifyContent={"flex-end"}>
                                                <Edit sx={{backgroundColor: "primary.main", padding: 1, borderRadius: 20, cursor: "pointer", color: "primary.text"}} onClick={() => setIsOnEditPrimaryColor(true)}/>
                                            </Box>}
                                            {isOnEditPrimaryColor && <Box sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                                                <SketchPicker sx={{ width: '100%' }} color={tempPrimaryColor}
                                                onChange={handlePrimaryColorChange}/>
                                                <Box>
                                                    <Button variant="outlined"  sx={{margin: 1, color: "primary.text", borderColor: "primary.text"}} onClick={() => validatePrimaryColor()} ><Check /></Button>
                                                    <Button variant="outlined"  sx={{margin: 1, color: "primary.text", borderColor: "primary.text"}} onClick={() => {
                                                        setTempPrimaryColor(primaryColor)
                                                        setIsOnEditPrimaryColor(false)
                                                        }} ><Close />
                                                    </Button>
                                                </Box>
                                                </Box>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"} >{t("PAGES.SETTINGS.FIELDS.SECONDARY_COLOR")}</Typography>
                                        <FormControl fullWidth margin="dense">
                                            {!isOnEditSecondaryColor && <Box bgcolor={secondaryColor} padding={2} height={40} borderRadius={2} display={"flex"} justifyContent={"flex-end"}>
                                                <Edit sx={{backgroundColor: "primary.main", padding: 1, borderRadius: 20, cursor: "pointer", color: "primary.text"}} onClick={() => setIsOnEditSecondaryColor(true)}/>
                                            </Box>}
                                            {isOnEditSecondaryColor && <Box sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                                                <SketchPicker sx={{ width: '100%' }} color={tempSecondaryColor}
                                                onChange={handleSecondaryColorChange}/>
                                                <Box>
                                                    <Button variant="outlined" sx={{margin: 1, color: "primary.text", borderColor: "primary.text"}} onClick={() => validateSecondaryColor()} ><Check /></Button>
                                                    <Button variant="outlined" sx={{margin: 1, color: "primary.text", borderColor: "primary.text"}} onClick={() => {
                                                        setTempSecondaryColor(secondaryColor)
                                                        setIsOnEditSecondaryColor(false)
                                                        }} ><Close />
                                                    </Button>
                                                </Box>
                                            </Box>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box bgcolor={"primary.light"} padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"}>
                                <Grid item xs={12} style={{position: "relative"}}>
                                    {companyLogo && <img src={companyLogo} alt={companyName} width="100%" style={{borderRadius: "20px"}}/>}
                                    <Edit sx={{backgroundColor: "primary.main", position: "absolute", right: 10, top: 10, padding: 1, borderRadius: 20, cursor: "pointer"}} onClick={pickImageHandler}/>
                                    <input
                                        type="file"
                                        ref={logoInputRef}
                                        name="image"
                                        onChange={handleCompanyLogoChange}
                                        style={{ display: 'none' }}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" mb={2} marginBlock={1}>
                        <Box display={"flex"} alignItems={"center"} onClick={handleSubmit}
                            sx={{cursor: "pointer", bgcolor: "primary.text", paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content"}}>
                            <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>{t('PAGES.SETTINGS.SAVE')}</Typography>
                        </Box>
                    </Box>
                </form>

        </PageContainer>
    );
};
