import { useState } from 'react';
import { Alert, Box, Button, Divider, Grid, Paper, TextField, Typography, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../redux/auth/authActions';
import { useNavigate } from 'react-router-dom';
import {theme} from "../../../theme"
import { MainHeight, MainHeightMobile, MainWidth, MainWidthMobile } from '../../../assets/constants.css';
import { UsersService } from '../../../common/services/users.service';

import statPageImage from "../../../assets/login-page-bg.png"
import logo from "../../../assets/ardealy-black-logo.png"
import { setLoading } from '../../../redux/vendor/ui/uiActions';

const LoginPage = ({originalLocation}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [usersService] = useState(new UsersService());
    const dispatch = useDispatch()
    const isMobileAndDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [mainHeight] = useState(isMobileAndDown ? MainHeightMobile: MainHeight)
    const [mainWidth] = useState(isMobileAndDown ? MainWidthMobile: MainWidth)

    const [isBadLogin, setIsBadLogin] = useState(false)

    const signIn = async (email, password) => {
      try {
        const auth = await usersService.authenticateUser(email, password)
        if(auth){
            localStorage.setItem("access_token", auth.access_token)
            dispatch(setUser(auth))
        } else {
            setIsBadLogin(true)
        }
      } catch (err){
        console.error(err);
        setIsBadLogin(true)
      }
    };


   const handleKeyPress = (event) => {
        if(event.keyCode === 13){
            signIn(email, password)
        }
    }

    //
    return (<Box>
            <Grid container style={{ height: mainHeight, borderRadius: 15,
                        userSelect: "none" }} bgcolor={"primary.main"}>
                <Grid item md={5}> 
                    <Box bgcolor={"white"} position={"relative"} height={"100%"} display={"flex"} flexDirection={"column"} alignItems="center" justifyContent={"space-between"} borderRadius={4}>
                        <Box textAlign={"center"} marginTop={2} display={"flex"}>
                            <img src={logo} style={{width: "120px"}}/>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} justifyContent="center" height={"100%"} width={"75%"} marginTop={-15} >
                            <Typography     variant='caption' textTransform={"capitalize"} fontWeight={"bolder"}
                                            sx={{fontSize: "20pt", letterSpacing: -0.5}} lineHeight={1.2}
                                            > Get started now </Typography>
                            <Typography     variant='caption' 
                                            sx={{fontSize: "11pt", letterSpacing: -0.5}}
                                             lineHeight={1}
                                            > Enter your credentials to access your account </Typography>

                            <Divider sx={{marginBlock: 4}}/>
                            <Typography variant='caption' sx={{fontSize: "11pt", letterSpacing: -0.5}}> Email </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="none"
                                size='small'
                                color='secondary'
                                value={email}
                                inputProps={{ sx: { fontSize: "11pt", letterSpacing: -0.5 }}}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Typography variant='caption' sx={{fontSize: "11pt", letterSpacing: -0.5, marginTop: 1}}> Password </Typography>
                            <TextField
                                type="password"
                                variant="outlined"
                                fullWidth
                                size='small'
                                color='secondary'
                                value={password}
                                inputProps={{ sx: { fontSize: "11pt", letterSpacing: -0.5 }}}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyDown={handleKeyPress} 
                            />
                            <Divider sx={{marginBlock: 2}}/>
                            <Box
                                onClick={() => signIn(email, password)}
                                padding={1.5} color={"primary.main"} borderRadius={2} textAlign={"center"}
                                sx={{cursor: "pointer", background: "linear-gradient(126deg, rgba(0,7,128,1) 0%, rgba(33,126,232,1) 100%)"}}
                            >
                                <Typography variant='caption' sx={{fontSize: "11pt", letterSpacing: -0.5, marginTop: 1}}> Login with Email/Password </Typography>
                            </Box>

                        {isBadLogin && <Alert severity="error" sx={{marginTop: 2}}>Please verify your email/password and try again.</Alert>}
                        </Box>
                        <Typography variant='caption' position={"absolute"} bottom={2} right={1.5}
                                    sx={{fontSize: "10pt", letterSpacing: -0.5}} lineHeight={1.2} 
                                    > powered by </Typography>
                    </Box>
                </Grid>
                <Grid item md={7} bgcolor={"primary.light"} height={"100%"} style={{
                        borderTopRightRadius: "14px", 
                        borderBottomRightRadius: "14px",
                        backgroundImage: `url(${statPageImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: "100% auto",
                        backgroundPosition: "center",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center", 
                        position: "relative"
                    }}>
                            <Typography variant='caption' bgcolor={"black"} color={"white"} padding={1} borderRadius={2}
                                        sx={{fontSize: "16pt", letterSpacing: -0.5}} lineHeight={1.2} marginRight={0.5}
                                        > The simplest way to manage  </Typography>
                            <Typography     variant='caption' fontWeight={"bolder"} bgcolor={"black"} padding={1} borderRadius={2}
                                            marginLeft={-7} marginTop={9.5}
                                        sx={{fontSize: "18pt", letterSpacing: -0.5}} lineHeight={1.2}  color={"white"}
                                        > your visualizer! </Typography>
                        <Typography     variant='caption' fontWeight={"bolder"} position={"absolute"} bottom={2} left={1.5}
                                        sx={{fontSize: "10pt", letterSpacing: -0.5}} lineHeight={1.2} 
                                        > ardealy </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default LoginPage;
