import { useDispatch } from "react-redux"
import Config from "../models/config.model";
import axios from "../../../../interceptors/api-client";
import VendorStats from "../models/stats.model";

export class AnalyticsService {
    dispatch = useDispatch()
    static settings: Config
    
    async getVendorStats() {
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/vendors/stats`
        };

        const response = await axios.request(config)
        const vendorStats: VendorStats = response.data
        return vendorStats
    }

    async queryAnalytics(query) {
      let config = {
          method: 'post',
          url: `${process.env.REACT_APP_PMS_API_URL}/services/pms/events/search`,
          data: query
        };

        const response = await axios.request(config)
        const vendorStats = response.data
        return vendorStats
    }

}